.customizePhoto {
  background-color: $black;
  color: #fff;
  border-left: 0;
  // @media (min-width: 1001px) {
  //   border-left: 1px solid rgba(255, 255, 255, 0.25);
  // }

  img {
    position: absolute;
    top: -100px;
    width: 400px;
    right: -304px;
  }
  h1 {
    font-size: 32px;
  }
  p,
  span {
    font-size: 10px;
  }
  &__offer {
    // padding: 20px 10px;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: 20px;

    &--paperType,
    &--paperSize {
      &__wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &--paperSize__wrapper {
      display: flex;
      @media (max-width: 1200) {
        display: flex;
      }
      @media (max-width: 756px) {
        display: block;
      }
    }
  }
  &__inner {
    padding: 45px 20px 60px 20px;
    min-height: auto;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: hidden;
    @media screen and (min-width: 1200px) and (max-height: 750px) {
      // min-height: 100vh;
      padding: 35px 60px 45px 60px !important;
    }
    @media (min-width: 1200px) {
      min-height: 100vh;
      padding: 45px 30px 60px 30px;
    }
    // @include md {
    //   min-height: 100vh;
    //   padding: 45px 30px 60px 30px;
    // }
    @include rwd(1455px) {
      padding: 45px 70px 60px 70px;
    }
    @include rwd(1370px) {
      padding: 85px 60px 60px 60px;
    }
  }
  &__heading {
    margin-bottom: 10px;
  }
}

.paperType {
  width: 25%;
  text-align: center;
  // margin-bottom: 20px;
  margin-bottom: 5px;
  &__icon {
    cursor: pointer;
    width: 31px;
    height: 25px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
    margin: 0 auto 10px;
  }
  &__details {
    font-size: 10px;
    line-height: 1.4;
  }
  &--active {
    .paperType {
      &__icon {
        border-color: $yellow;
      }
      &__details {
        color: $yellow;
        opacity: 1;
        font-weight: 400;
      }
    }
  }
}
.paperSize {
  width: auto;
  text-align: center;
  margin-bottom: 20px;
  z-index: 10;
  @include md {
    width: calc(100% / 6);
  }
  &__details {
    font-size: 10px;
    line-height: 1.4;

    &--ratios {
      margin-bottom: 5px;
      opacity: 0.4;
    }
  }
  &__icon {
    cursor: pointer;
    border-radius: 1px;
    border: 1px solid #fff;
    &--wrapper {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 32px;
    }
  }
  &--active {
    .paperSize {
      &__icon {
        border-color: $yellow;
        position: relative;
        overflow: hidden;
        #triangle-topleft {
          border-right: 2px solid $yellow;
          width: 100%;
          height: 100%;
          left: calc(-50% + 2px);
          position: absolute;
          top: 0px;
          z-index: 10;
        }
      }
      &__details {
        &--ratios {
          color: $yellow;
          opacity: 1;
          font-weight: 400;
        }
      }
    }
  }
}
.customizePhotoFooter {
  padding: 0 5px;
  margin-top: auto;
  position: relative;
  &__selected-photos-amount {
    padding-bottom: 20px;
    font-size: 11px;
    font-weight: 400;
    display: flex;
    align-items: center;
    p {
      opacity: 0.55;
      margin: 0;
    }
    &--bold {
      margin-left: 10px;
      font-weight: 500;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    // margin-bottom: auto;
    // margin-top: 60px;
    justify-content: center;
  }
  &__summary,
  &__count,
  &__actions {
    width: calc(100% / 3);
    // margin: 0 10px;
    height: 46px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 56px;
    margin-left: 5px;
    .btn {
      margin-left: auto;
    }
  }
  &__count {
    text-align: center;
    z-index: 10;
  }
  &__summary {
    h5 {
      font-size: 10px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
      &.customizePhotoFooter__summary {
        &--price {
          font-size: 22px;
          line-height: 1;
        }
      }
    }
  }
  &__price-per-item {
    position: absolute;
    font-size: 10px;
    color: #fff;
    bottom: -25px;
    margin-bottom: 0;
    // @media (min-width: 1201px) {
    //   bottom: 20px;
    // }
    // @media (max-width: 1200px) {
    //   bottom: 5px;
    // }
    span {
      opacity: 0.53;
      margin-right: 5px;
    }
  }
}

.opacity {
  opacity: 0.5;
}
