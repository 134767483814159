@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900|Raleway:300,400,500,700,900");
@import url("https://geowidget.easypack24.net/css/easypack.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Small devices */
/* Medium devices */
/* Large devices */
/* Extra large devices */
/* Extra large devices */
/* Custom breakpoint */
* {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.5;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.btn {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.4;
  font-weight: 700;
  color: #000000;
  padding: 11px 21px;
  cursor: pointer;
  border-radius: 7px;
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.btn:active, .btn:focus {
  outline: none;
}
.btn .content {
  display: flex;
  align-items: center;
}
.btn .content p {
  margin-bottom: 0;
}
.btn .content--hide {
  display: none;
  opacity: 0;
}
.btn--new-configuration {
  width: 155px;
  white-space: nowrap;
}
@media (min-width: 400px) {
  .btn--new-configuration {
    width: auto;
  }
}
.btn--back {
  white-space: nowrap;
  width: 152px;
}
.btn--back .content {
  width: 100%;
}
.btn--loader {
  margin-right: 5px;
}
.btn--loader__inner {
  width: 17px;
  height: 17px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-bottom-color: transparent;
  animation: spinx 0.7s infinite linear both;
}
@media (max-width: 768px) {
  .btn--back-order .content {
    width: 170px !important;
  }
}
@media (max-width: 354px) {
  .btn--back-order .content {
    white-space: pre-wrap !important;
    width: 100px !important;
  }
  .btn--back-order .btn__icon {
    flex-shrink: 0;
  }
}
.btn.is-loading {
  padding-left: 22px;
  display: flex;
  align-items: center;
}
.btn--gray {
  background-color: rgba(0, 0, 0, 0.0705882353);
}
.btn--yellow {
  background-color: #ffc400;
}
.btn--icon {
  padding-top: 9px;
  padding-bottom: 9px;
}
.btn--disabled {
  background-color: #dbdbdb;
  color: #a8a8a8;
  cursor: not-allowed;
}
.btn--disabled .svg .a {
  stroke: #a8a8a8;
}
.btn--success {
  background-color: rgb(53, 221, 128);
  color: #fff;
}
.btn--success .content {
  opacity: 1;
  animation: fade 1.3s;
}
.btn--success svg {
  height: 24px;
  width: 24px;
}
.btn--success svg .a {
  fill: #fff !important;
  stroke: #fff !important;
}
.btn__icon-placement--right .btn__icon {
  padding-left: 13px;
  margin-right: -10px;
  height: 17px;
  width: fit-content;
  max-width: 30px;
}
.btn__icon-placement--left .btn__icon {
  margin-right: 13px;
  height: 17px;
  width: fit-content;
  max-width: 30px;
}
.btn i {
  font-size: 16px;
}
.btn--black, .btn--red {
  background-color: #000000;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.35);
  color: #fff;
}
.btn--black svg .a, .btn--red svg .a {
  stroke: #fff;
}
.btn--red {
  color: red;
}
.btn__no-label {
  padding: 11px;
}
.btn__no-label .btn__icon {
  padding: 0;
}
.btn--clean {
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}
.btn-basket span {
  width: 77px;
  justify-content: space-between;
}
.btn-basket span .btn__icon {
  padding-left: 2px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinx {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;
}

h1 {
  font-size: 43px;
  line-height: 1;
  margin-bottom: 15px;
}

.yellow {
  color: #ffc400;
}

p {
  margin-bottom: 15px;
}

.add-to-cart {
  --color: #000;
  --icon: var(--color);
  --cart: #000;
  --dots: #000;
  --background: #ffc400;
  --shadow: rgba(0, 9, 61, 0.16);
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  border-radius: 7px;
  padding: 11px 32px;
  font-weight: 700;
  line-height: 20px;
  transform: scale(var(--s, 0.97));
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform 0.3s, box-shadow 0.3s;
}
.add-to-cart--disabled {
  background-color: #dbdbdb;
  color: #a8a8a8;
  cursor: not-allowed;
}
.add-to-cart--disabled .svg .a {
  stroke: #a8a8a8;
}
.add-to-cart .default {
  padding-left: 16px;
  position: relative;
  opacity: var(--o, 1);
  transform: scale(var(--s, 1));
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: var(--d, 0.3s);
}
.add-to-cart .success {
  opacity: var(--o, 0);
  transform: translate(-50%, var(--y, 12px)) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: var(--d, 0s);
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-to-cart .success svg {
  height: 18px;
  width: 18px;
  margin-left: 15px;
}
.add-to-cart .dots {
  width: 3px;
  height: 3px;
  top: 17px;
  left: 50%;
  margin-left: -5px;
  border-radius: 2px;
  position: absolute;
  transform-origin: 10px 50%;
  background: var(--dots);
  box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  opacity: var(--o, 0);
  transform: scale(var(--s, 0.4));
  transition: opacity 0.3s, transform 0.1s;
  transition-delay: var(--d, 0s);
}
.add-to-cart .cart {
  position: absolute;
  top: 11px;
  right: -60px;
  bottom: 0;
  z-index: 1;
  border-radius: inherit;
  overflow: hidden;
  left: -125px;
}
.add-to-cart .cart svg {
  height: 17px;
  width: fit-content;
  opacity: var(--o, 1);
  transform: scale(var(--s, 1));
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: var(--d, 0.3s);
}
.add-to-cart.added .cart {
  --o: 0;
  --s: 0.8;
  --d: 2s;
}
.add-to-cart.added .cart:after {
  --r: -90deg;
}
.add-to-cart.added .default {
  --o: 0;
  --s: 0.8;
  --d: 0s;
}
.add-to-cart.added .default:before {
  --r: -180deg;
}
.add-to-cart.added .default:after {
  --r: -90deg;
}
.add-to-cart.added .dots {
  --o: 1;
  --s: 1;
  --d: 0.3s;
  animation: dots 2s linear forwards;
}
.add-to-cart.added .success {
  --o: 1;
  --y: 0;
  --d: 1.8s;
}
.add-to-cart.added .cart {
  animation: cart 2s forwards;
}
.add-to-cart.added .cart:before, .add-to-cart.added .cart > div {
  animation: cart 2s forwards;
}
.add-to-cart:not(.added):hover {
  --s: 1;
  --s-y: 8px;
  --s-b: 20px;
}
.add-to-cart:not(.added):active {
  --s: 0.94;
  --s-y: 2px;
  --s-b: 6px;
}

@keyframes cart {
  41%, 49%, 57%, 60% {
    transform: translateX(31px) rotate(0deg);
  }
  40%, 47%, 54% {
    transform: translateX(31px) rotate(0deg) translateY(1px);
  }
  100% {
    transform: translateX(180px) rotate(-16deg);
  }
}
@keyframes dots {
  5% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-22px) rotate(-32deg);
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  }
  31% {
    box-shadow: 5px -4px 0 var(--dots), 10px -8px 0 var(--dots);
  }
  32%, 50% {
    transform: translateY(-3px) rotate(0deg);
    opacity: 1;
  }
  45%, 100% {
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  }
  51%, 100% {
    opacity: 0;
  }
}
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

.main-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}
.main-wrapper--outerBannerVisible {
  min-height: 100vh;
  display: flex;
}
.main-wrapper--outerBannerVisible .page__inner {
  width: 100%;
  position: relative;
}
@media (max-width: 756px) {
  .main-wrapper--outerBannerVisible .page__inner {
    width: 100%;
  }
}
.main-wrapper--outerBannerVisible .outerBanner {
  width: 35%;
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .outerBanner {
    width: 100%;
  }
}
.main-wrapper--100vh {
  height: 100vh !important;
}

.main-wrapper--outerBannerVisible .photo-prints {
  display: none;
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible {
    display: flex;
    flex-direction: column;
  }
  .main-wrapper--outerBannerVisible .screen--photo-print {
    min-height: calc(100vh - 120px);
  }
}
@media (max-width: 1200px) and (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .screen--photo-print {
    height: 0;
    min-height: calc(100vh - 120px);
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .sliderGallery--nav,
.main-wrapper--outerBannerVisible .customizePhoto__heading,
.main-wrapper--outerBannerVisible .customizePhotoFooter__summary--forEach,
.main-wrapper--outerBannerVisible .customizationPanelFooter--r .btn__icon-placement--right {
    display: none;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizePhotoFooter {
    order: 3;
    padding: 0;
    margin-top: 0;
    margin-top: 30px;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizePhoto__offer {
    order: 2;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizePhoto__inner {
    padding-top: 0;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .counter__btn {
    padding: 10px 5px;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizationPanel .container {
    padding: 0;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizePhoto__offer--paperType {
    margin: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .customizePhoto img {
    display: none;
  }
}
@media (max-width: 1200px) {
  .main-wrapper--outerBannerVisible .action-bar {
    flex-wrap: wrap !important;
  }
}
.main-wrapper--outerBannerVisible .page__inner {
  transition: opacity 0.2s;
}
.main-wrapper--outerBannerVisible .page__inner.blurred {
  transition: filter 0.2s ease-out, opacity 0.2s;
}
.main-wrapper--outerBannerVisible .outerBanner {
  z-index: 999;
}
.main-wrapper--outerBannerVisible .outerBanner--mg-80 {
  margin-top: 80px;
}

@media (min-width: 1200px) {
  .blurred .header {
    opacity: 0.3;
    filter: blur(10px);
  }
}
.blurred .screen--photo-print {
  opacity: 0.3;
  filter: blur(10px);
}

.shadowed {
  box-shadow: 0px 4px 21px #dadada;
}

@keyframes fadeIn2 {
  0% {
    width: 0;
  }
  100% {
    width: 35%;
  }
}
.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1224px) {
  .landing-page {
    height: 970px;
  }
}
@media (max-width: 756px) {
  .landing-page {
    height: auto;
  }
}
.landing-page h1 {
  font-weight: 900;
  line-height: 37pt;
  margin-top: 100px;
  position: relative;
  z-index: 3;
  margin-left: 84px;
}
.landing-page h1 strong {
  color: #ffc400;
}
@media (min-width: 420px) {
  .landing-page h1 {
    font-size: 38pt;
  }
}
@media (min-width: 756px) {
  .landing-page h1 {
    left: 80px;
  }
}
@media (min-width: 1024px) {
  .landing-page h1 {
    position: absolute;
    left: 210px;
    margin: 0%;
    font-size: 70px;
    line-height: 58pt;
    top: 28%;
  }
}
@media (min-width: 1420px) {
  .landing-page h1 {
    line-height: 70pt;
    font-size: 84px;
    top: 20%;
  }
}
@media (max-height: 650px) and (min-width: 1000px) {
  .landing-page h1 {
    margin-top: -30px;
  }
}
.landing-page--img {
  position: absolute;
  top: 0;
  margin-top: -350px;
}
@media (max-height: 650px) and (min-width: 1000px) {
  .landing-page--img {
    margin-top: -400px;
  }
}
.landing-page--link {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.landing-page__creator-link {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  background-color: #ffc400;
  border: 0;
  border-radius: 7px;
  box-shadow: 0pt 3pt 30pt rgba(0, 0, 0, 0.3215686275);
  cursor: pointer;
  color: #000000;
  display: flex;
  font-weight: 700;
  padding: 10pt 15pt;
  font-size: 17px;
  position: relative;
  z-index: 3;
  margin-bottom: 200px;
  margin-top: 170px;
}
@media (min-width: 420px) {
  .landing-page__creator-link {
    font-size: 17px;
    padding: 11pt 15pt;
  }
}
@media (min-width: 756px) {
  .landing-page__creator-link {
    position: absolute;
    left: 65%;
    bottom: 47%;
    font-size: 13px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .landing-page__creator-link {
    left: 70%;
  }
}
@media (min-width: 1420px) {
  .landing-page__creator-link {
    bottom: 50%;
    font-size: 17px;
    margin: 0%;
    z-index: 2;
  }
}
.landing-page__creator-link:after {
  content: "";
  background-image: url("../../Img/arrow.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: 15px;
  height: 22px;
  margin-left: 30px;
}
.landing-page .bg {
  z-index: 2;
  margin-left: -80px;
  margin-top: -100px;
  width: 1200px;
}
@media (min-width: 756px) {
  .landing-page .bg {
    right: 0;
    margin-right: -370px;
    margin-top: -300px;
    width: 1550px;
  }
}
@media (min-width: 1420px) {
  .landing-page .bg {
    margin-right: -350px;
  }
}
.landing-page .frame {
  width: 1000px;
  left: -820px;
  top: 10%;
  z-index: 3;
}
@media (min-width: 756px) {
  .landing-page .frame {
    left: -880px;
    width: 1148px;
  }
}
@media (min-width: 1420px) {
  .landing-page .frame {
    left: -1080px;
    top: -8%;
    width: 1435px;
  }
}

.photo-prints {
  position: relative;
  margin-top: 20px;
  padding-bottom: 70px;
}
.photo-prints--unscrollable {
  overflow: hidden;
}
.photo-prints__gallery-wrapper {
  transition: 0.4s filter linear;
}
.photo-prints__gallery-wrapper--blurred {
  filter: blur(4px) brightness(1.2);
}
@media (min-width: 1025px) {
  .photo-prints {
    min-height: 100vh;
  }
}
@media (max-width: 756px) {
  .photo-prints {
    height: auto;
  }
}

.screen--photo-print .action-bar {
  flex-wrap: wrap-reverse;
}
@media (max-width: 530px) {
  .screen--photo-print .btn--wrapper:first-child {
    padding-left: 0;
  }
}
.screen--photo-print .btn--basket {
  display: none;
}
@media (max-width: 756px) {
  .screen--photo-print .btn--basket {
    display: block;
    position: absolute;
    bottom: 50px;
    right: 17px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    background-image: url("../../Img/icon-basket.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

.customizationPanel {
  position: absolute;
  left: 0;
  top: 65px;
  right: 0;
  background-color: #000000;
  min-height: calc(100vh - 60px);
  padding: 0;
  z-index: 102;
}
@media (min-width: 756px) {
  .customizationPanel {
    top: 75px;
  }
}
@media (max-width: 1200px) {
  .customizationPanel {
    height: auto;
    min-height: auto;
    padding-bottom: 30px;
    bottom: 0;
  }
}
.customizationPanel .container {
  position: relative;
}
.customizationPanel__framing-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
@media (max-width: 485px) {
  .customizationPanel__framing-header {
    flex-direction: column;
  }
  .customizationPanel__framing-header--l, .customizationPanel__framing-header--r {
    justify-content: space-between;
  }
  .customizationPanel__framing-header--l:first-of-type, .customizationPanel__framing-header--r:first-of-type {
    margin-bottom: 15px;
  }
}
.customizationPanel__framing-header--l, .customizationPanel__framing-header--r {
  display: flex;
}
.customizationPanel__framing-header--l .btn--wrapper:first-of-type {
  margin-right: 13px;
}
.customizationPanel__framing {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
}

.customizationPanelFooter {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0 10px;
}
.customizationPanelFooter__actions {
  display: flex;
  align-items: center;
}
.customizationPanelFooter .image-name {
  font-size: 12px;
}
.customizationPanelFooter--l .btn--wrapper {
  padding-right: 15px;
}
.customizationPanelFooter--r .btn--wrapper {
  padding-left: 15px;
}

.sliderGallery img {
  max-width: 100%;
  max-height: calc(100vh - 330px);
  object-fit: contain;
}
.sliderGallery--single {
  height: calc(100vh - 300px);
}
.sliderGallery--single__item_inner {
  height: calc(100vh - 330px);
  margin-top: 10px;
}
.sliderGallery--single__item {
  outline: none;
}
.sliderGallery--single__item img {
  margin: 0 auto;
}
.sliderGallery--single__item_inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderGallery--active-item {
  border-color: #ffc400 !important;
}
.sliderGallery--nav__item {
  outline: none;
}
.sliderGallery .slick-slide {
  cursor: pointer;
}
.sliderGallery .slick-slide .sliderGallery--nav__item_inner {
  border: 2px solid transparent;
}
.sliderGallery--nav__item {
  padding: 5px;
}
.sliderGallery--nav__item img {
  margin: 0 auto;
  max-width: 200px;
  min-width: 80px;
  object-fit: contain;
}

.react-photo-gallery--gallery > div > img {
  z-index: 101;
  object-fit: contain;
}

.react-photo-gallery--gallery {
  margin-bottom: 100px;
  padding: 0 5px;
}
@media (max-width: 1200px) {
  .react-photo-gallery--gallery {
    margin-bottom: 0;
  }
}

.selectedPhotosGallery {
  overflow: auto;
  padding-bottom: 160px;
  height: 100vh;
  background-color: #000;
}

.customizePhoto {
  background-color: #000000;
  color: #fff;
  border-left: 0;
}
.customizePhoto img {
  position: absolute;
  top: -100px;
  width: 400px;
  right: -304px;
}
.customizePhoto h1 {
  font-size: 32px;
}
.customizePhoto p,
.customizePhoto span {
  font-size: 10px;
}
.customizePhoto__offer {
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin-bottom: 20px;
}
.customizePhoto__offer--paperType__wrapper, .customizePhoto__offer--paperSize__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.customizePhoto__offer--paperSize__wrapper {
  display: flex;
}
@media (max-width: 1200) {
  .customizePhoto__offer--paperSize__wrapper {
    display: flex;
  }
}
@media (max-width: 756px) {
  .customizePhoto__offer--paperSize__wrapper {
    display: block;
  }
}
.customizePhoto__inner {
  padding: 45px 20px 60px 20px;
  min-height: auto;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
}
@media screen and (min-width: 1200px) and (max-height: 750px) {
  .customizePhoto__inner {
    padding: 35px 60px 45px 60px !important;
  }
}
@media (min-width: 1200px) {
  .customizePhoto__inner {
    min-height: 100vh;
    padding: 45px 30px 60px 30px;
  }
}
@media (min-width: 1px) {
  .customizePhoto__inner {
    padding: 45px 70px 60px 70px;
  }
}
@media (min-width: 1px) {
  .customizePhoto__inner {
    padding: 85px 60px 60px 60px;
  }
}
.customizePhoto__heading {
  margin-bottom: 10px;
}

.paperType {
  width: 25%;
  text-align: center;
  margin-bottom: 5px;
}
.paperType__icon {
  cursor: pointer;
  width: 31px;
  height: 25px;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  margin: 0 auto 10px;
}
.paperType__details {
  font-size: 10px;
  line-height: 1.4;
}
.paperType--active .paperType__icon {
  border-color: #ffc400;
}
.paperType--active .paperType__details {
  color: #ffc400;
  opacity: 1;
  font-weight: 400;
}

.paperSize {
  width: auto;
  text-align: center;
  margin-bottom: 20px;
  z-index: 10;
}
@media (min-width: 756px) {
  .paperSize {
    width: 16.6666666667%;
  }
}
.paperSize__details {
  font-size: 10px;
  line-height: 1.4;
}
.paperSize__details--ratios {
  margin-bottom: 5px;
  opacity: 0.4;
}
.paperSize__icon {
  cursor: pointer;
  border-radius: 1px;
  border: 1px solid #fff;
}
.paperSize__icon--wrapper {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
}
.paperSize--active .paperSize__icon {
  border-color: #ffc400;
  position: relative;
  overflow: hidden;
}
.paperSize--active .paperSize__icon #triangle-topleft {
  border-right: 2px solid #ffc400;
  width: 100%;
  height: 100%;
  left: calc(-50% + 2px);
  position: absolute;
  top: 0px;
  z-index: 10;
}
.paperSize--active .paperSize__details--ratios {
  color: #ffc400;
  opacity: 1;
  font-weight: 400;
}

.customizePhotoFooter {
  padding: 0 5px;
  margin-top: auto;
  position: relative;
}
.customizePhotoFooter__selected-photos-amount {
  padding-bottom: 20px;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.customizePhotoFooter__selected-photos-amount p {
  opacity: 0.55;
  margin: 0;
}
.customizePhotoFooter__selected-photos-amount--bold {
  margin-left: 10px;
  font-weight: 500;
}
.customizePhotoFooter__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizePhotoFooter__summary, .customizePhotoFooter__count, .customizePhotoFooter__actions {
  width: 33.3333333333%;
  height: 46px;
}
.customizePhotoFooter__actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 56px;
  margin-left: 5px;
}
.customizePhotoFooter__actions .btn {
  margin-left: auto;
}
.customizePhotoFooter__count {
  text-align: center;
  z-index: 10;
}
.customizePhotoFooter__summary h5 {
  font-size: 10px;
  margin-bottom: 10px;
}
.customizePhotoFooter__summary p {
  margin-bottom: 0;
}
.customizePhotoFooter__summary p.customizePhotoFooter__summary--price {
  font-size: 22px;
  line-height: 1;
}
.customizePhotoFooter__price-per-item {
  position: absolute;
  font-size: 10px;
  color: #fff;
  bottom: -25px;
  margin-bottom: 0;
}
.customizePhotoFooter__price-per-item span {
  opacity: 0.53;
  margin-right: 5px;
}

.opacity {
  opacity: 0.5;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.action-bar__title {
  margin: 20px 0 0 20px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 385px) {
  .action-bar__title {
    margin: 20px 0 0 0;
  }
}
.action-bar--r, .action-bar--l {
  display: flex;
  flex-wrap: wrap;
}
.action-bar--r .yellow-icon .svg--select .b,
.action-bar--r .yellow-icon .svg--select .c {
  fill: #ffc400;
}
.action-bar--r .yellow-icon .svg--select .a > .b {
  fill: none;
}
.action-bar--r .btn-basket {
  padding-right: 21px;
}
.action-bar--r .btn-basket .content {
  width: 80px;
  white-space: nowrap;
}
.action-bar--r .btn--wrapper {
  padding-right: 13px;
}
.action-bar--r .btn--wrapper:last-of-type {
  padding-right: 0;
}
.action-bar .btn--wrapper {
  margin-top: 20px;
}
.action-bar--l .btn--wrapper {
  padding-right: 13px;
}
.action-bar--customizationPanel {
  margin-bottom: 10px;
}

.counter__inner {
  display: flex;
  justify-content: center;
  font-size: 22px;
  line-height: 15px;
}
.counter__label {
  font-size: 10px;
  margin-bottom: 4px;
}
.counter__value {
  position: relative;
  padding: 10px 10px 0 10px;
  min-width: 45px;
  text-align: center;
  border-bottom: 1px solid #545454;
}
.counter__input {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background: transparent;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 22px;
  outline: none;
}
.counter__btn {
  padding: 10px;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  z-index: 2;
}
@media (max-width: 1180px) {
  .counter__btn {
    padding: 10px 5px;
  }
}

.checkout {
  color: #000000;
  position: relative;
  background-color: #fff;
}
@media (max-width: 1200px) {
  .checkout {
    position: absolute;
    top: 65px;
    width: 100%;
    background-color: #fff;
    height: 100%;
  }
}
.checkout__inner {
  animation: fadeIn 0.1s;
  padding: 70px 70px 70px 40px;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  justify-content: space-between;
}
.checkout__inner a {
  text-decoration: none;
}
@media (max-width: 1440px) {
  .checkout__inner {
    padding: 70px 30px 50px 30px;
  }
}
@media (min-width: 1201px) {
  .checkout__inner {
    height: 100vh;
  }
}
@media (max-width: 1240px) {
  .checkout__inner {
    padding: 70px 30px 50px 10px;
  }
}
@media (max-width: 1046px) {
  .checkout__inner {
    padding-left: 0;
  }
}
@media (max-width: 1200px) {
  .checkout__inner {
    padding: 30px 50px 30px 30px;
    max-height: calc(100vh - 170px);
    min-height: auto;
    overflow: hidden;
  }
}
@media (max-width: 450px) {
  .checkout__inner {
    padding: 20px 10px 20px 10px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  .checkout .checkout__inner {
    max-height: calc(100vh - 265px);
  }
  .checkout .checkout__sum-wrapper {
    position: fixed;
    height: 100px;
    bottom: 105px;
  }
}
@media (max-width: 1200px) {
  .checkout__heading--wrapper {
    display: flex;
    justify-content: center;
  }
}
.checkout__items {
  overflow: scroll;
  height: 100%;
  padding-bottom: 60px;
}
@media (min-width: 1201px) {
  .checkout__items {
    padding-bottom: 140px;
  }
}
.checkout__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  max-width: 500px;
}
@media (max-width: 1200px) {
  .checkout__heading {
    max-width: 100%;
  }
}
.checkout__heading h1 {
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}
@media (max-width: 755px) {
  .checkout__heading h1 {
    font-size: 24px;
  }
}
.checkout__heading .btn {
  background-color: transparent;
  position: relative;
  width: 155px;
  font-size: 12px;
  padding-left: 0;
  padding-right: 60px;
  z-index: 10;
}
.checkout__heading .btn .content {
  width: 100%;
}
.checkout__heading .btn:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 100%;
  background-image: url("../../Img/icon-basket.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  background-color: #ffc400;
  border-radius: 6px;
}
@media (max-width: 1200px) {
  .checkout__heading {
    max-width: 500px;
    width: 100%;
  }
}

.checkoutItem {
  display: flex;
  margin: 7px;
  height: 114px;
  position: relative;
  padding-right: 20px;
}
.checkoutItem__img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 110px;
}
.checkoutItem__img img {
  height: 100%;
  object-fit: contain;
  position: static;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 1200px) {
  .checkoutItem {
    justify-content: center;
  }
}
@media (max-width: 667px) {
  .checkoutItem {
    padding: 0 25px 0 0;
  }
}

.checkout__bg {
  position: absolute;
  width: 22%;
  right: -17%;
  top: -10%;
  z-index: 10;
}
@media (max-width: 1200px) {
  .checkout__bg {
    top: -6%;
    width: 23%;
  }
}
@media (max-width: 756px) {
  .checkout__bg {
    top: -4%;
    width: 30%;
    right: -23%;
  }
}
.checkout__sum--wrapper {
  padding: 0 15px 15px 15px;
  padding: 10px 15px 0px 15px;
}
@media (max-width: 1200px) {
  .checkout__sum--wrapper {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1201px) {
  .checkout__sum--wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 30;
    background-color: #fff;
  }
}
.checkout__summary {
  position: relative;
  padding: 0 7px 7px 7px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.checkout__summary:after {
  background-color: rgba(0, 0, 0, 0.0705882353);
  content: "";
  height: 100%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 500px) {
  .checkout__summary {
    padding: 0 0 7px 7px;
  }
}
.checkout__summary .checkout__price {
  position: absolute !important;
  right: 10px;
}
.checkout__row {
  display: flex;
  align-items: center;
  padding: 0 5px;
}
@media (max-width: 500px) {
  .checkout__row {
    padding: 0 0 0 7px;
  }
}
.checkout__row .counter {
  margin: 0 5px;
}
@media (min-width: 400px) {
  .checkout__row .counter {
    margin-left: 10px;
  }
}
.checkout__row .counter__value {
  background-color: #ededed;
  border-radius: 7px;
  min-height: 30px;
  z-index: 10;
  border-color: #fff;
}
.checkout__row .counter__inner {
  align-items: center;
}
.checkout__row .counter__input {
  color: #000;
  font-size: 18px;
}
.checkout__row:nth-child(2) {
  margin-top: 10px;
}
.checkout__row:nth-child(3) .checkout__price {
  font-weight: 700;
  flex-shrink: 0;
  position: absolute;
}
.checkout__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}
.checkout__title span:first-child {
  width: 48px;
  font-weight: 600;
}
@media (min-width: 400px) {
  .checkout__title span:first-child {
    width: 55px;
  }
}
.checkout__title .bold {
  font-weight: 700;
}
.checkout__price {
  display: flex;
  align-items: center;
}
.checkout__price--bold {
  font-size: 18px;
  font-weight: 700;
}
.checkout__sum {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.checkout__sum p {
  font-size: 13px;
  margin-bottom: 0;
  margin-right: 7px;
}
.checkout__sum a {
  text-decoration: none;
}
.checkout__sum-contact {
  font-size: 12px;
  color: #b1b1b1;
  text-decoration: underline !important;
  text-align: center;
  width: 100%;
  margin: 15px auto;
  display: block;
}
@media (max-width: 650px) {
  .checkout__sum {
    padding: 0px 15px;
  }
}
.checkout__close {
  position: absolute;
  right: -30px;
  top: 0px;
  height: 20px;
  border-radius: 3px;
  z-index: 11;
}
.checkout__close svg {
  width: 20px;
  height: 20px;
}
.checkout__close:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
.checkout__close:hover svg .a {
  fill: rgb(66, 66, 66);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.order {
  display: flex;
  padding: 10px 79px 0 57px;
  overflow-y: auto;
  min-height: 100vh;
}
.order a {
  width: fit-content;
}
.order__basket-mobile {
  display: none;
}
@media (max-width: 768px) {
  .order__basket-mobile {
    display: block;
    position: absolute;
    right: 30px;
    top: 9px;
    display: flex;
    font-size: 13px;
    font-weight: 500;
  }
  .order__basket-mobile span {
    display: flex;
  }
  .order__basket-mobile span p {
    font-weight: 700;
    margin: 0 15px;
  }
}
@media (max-width: 354px) {
  .order__basket-mobile {
    top: 20px;
  }
}
.order__basket-mobile-icon {
  margin-top: -4px;
  position: relative;
}
.order__basket-mobile-icon p {
  position: absolute;
  right: -5px;
  bottom: -15px;
  background-color: #ffc400;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
}
.order__left {
  width: 65%;
  margin-right: 25px;
}
.order__left--heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0 15px;
}
.order__left--heading .order__left--bold {
  font-weight: 700;
  display: inline-block;
  font-size: 12px;
}
.order__left--heading span {
  display: flex;
  font-size: 13px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .order__left--heading span {
    display: none;
  }
}
.order__left--heading span p {
  padding: 0 15px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .order__left--heading span p {
    display: none;
  }
}
.order__left--heading-delivery {
  margin-top: 30px;
}
.order__left--basket {
  display: none;
}
@media (max-width: 768px) {
  .order__left--basket {
    display: block;
  }
}
.order__left a {
  text-decoration: none;
}
@media (max-width: 768px) {
  .order__left {
    position: relative;
  }
}
.order__right {
  width: 60%;
  max-width: 550px;
}
@media (max-width: 1080px) {
  .order__right {
    max-width: 550px;
  }
  .order__right .form__data--heading {
    padding-top: 35px !important;
  }
}
.order__right .form__data--heading {
  padding-top: 128px;
  margin-bottom: 10px;
}
.order__box-dotpay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}
.order__box-dotpay span {
  width: 62px;
  text-align: center;
}
.order__box-dotpay span img {
  height: 20px;
}
.order__box-dotpay a {
  text-decoration: none !important;
}
.order__products-list {
  position: relative;
  padding: 16px 28px 100px 20px;
}
.order__product {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 17px;
}
.order__product:last-of-type {
  border-bottom: unset;
  margin-bottom: 25px;
  padding-bottom: unset;
}
.order__product--name, .order__product--size, .order__product--paper, .order__product--prints {
  display: flex;
  font-size: 11px;
}
.order__product--name p, .order__product--size p, .order__product--paper p, .order__product--prints p {
  width: 100px;
  margin-bottom: 7px;
  font-weight: 500;
}
.order__product--name span, .order__product--size span, .order__product--paper span, .order__product--prints span {
  font-weight: 700;
}
.order__product--left {
  width: 100%;
}
.order__product--right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 90px;
  max-width: 190px;
}
.order__product--right p {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
}
.order h1 {
  font-size: 32px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .order h1 {
    font-size: 23px;
    line-height: 28px;
    padding: 0 17px;
  }
}
.order button {
  margin-bottom: 19px;
}
@media (max-width: 768px) {
  .order .btn--order {
    background-color: #f9f9f9;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 0;
    justify-content: flex-start;
    padding-bottom: 25px;
    padding-left: 12px;
    width: 100%;
    font-size: 13px;
  }
  .order .btn--order .content {
    width: 140px;
    white-space: nowrap;
  }
}
.order .btn__icon i {
  font-size: 12px;
}
@media (max-width: 768px) {
  .order .btn__icon i {
    font-size: 14px;
  }
}
@media (max-width: 1080px) {
  .order {
    flex-direction: column;
  }
  .order__left, .order__right {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .order {
    padding: 0;
  }
}

.photo {
  height: calc(100vh - 230px);
  overflow-y: auto;
}
@media (max-width: 1080px) {
  .photo {
    height: auto;
  }
}

.photo__single {
  display: flex;
  height: 143px;
  position: relative;
  padding-right: 40px;
}
.photo__single--left {
  height: 133px;
  width: 182px;
  padding: 5px;
  flex-shrink: 0;
  position: relative;
}
.photo__single--left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.photo__single--left-prints {
  display: none;
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 18px;
  height: 14px;
  font-size: 10px;
  font-weight: 600;
  background-color: #ffc400;
  text-align: center;
}
@media (max-width: 768px) {
  .photo__single--left-prints {
    display: block;
  }
}
@media (max-width: 768px) {
  .photo__single--left {
    max-height: 78px;
    width: 100px;
  }
}
.photo__single--right {
  display: flex;
  width: 100%;
  padding-left: 11px;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}
.photo__single--right--info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
@media (max-width: 768px) {
  .photo__single--right--info {
    justify-content: space-around;
  }
}
.photo__single--right--price {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .photo__single--right--price {
    justify-content: space-around;
  }
}
.photo__single--right--price p {
  width: 100px;
  margin-bottom: -7px;
  font-size: 10px;
  text-align: right;
}
@media (min-width: 756px) {
  .photo__single--right--price p {
    margin-bottom: 4px;
  }
}
.photo__single--right--price p:last-child {
  font-weight: 700;
  font-size: 15px;
}
@media (max-width: 768px) {
  .photo__single--right--price p {
    width: auto;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .photo__single--right {
    border: 0;
  }
}
.photo__single .checkout__close {
  right: 10px;
  top: 5px;
}
.photo__single--close {
  padding-left: 20px;
}
.photo__single--close i {
  color: #e5e5e5;
  font-size: 20px;
  cursor: pointer;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .photo__single--close {
    display: none;
  }
}
.photo__single--name, .photo__single--size, .photo__single--paper, .photo__single--prints {
  display: flex;
  font-size: 10px;
}
.photo__single--name p, .photo__single--size p, .photo__single--paper p, .photo__single--prints p {
  width: 100px;
  margin-bottom: 7px;
}
.photo__single--name span, .photo__single--size span, .photo__single--paper span, .photo__single--prints span {
  font-weight: 700;
}
@media (max-width: 768px) {
  .photo__single--name, .photo__single--size, .photo__single--paper, .photo__single--prints {
    font-size: 13px;
  }
  .photo__single--name p, .photo__single--size p, .photo__single--paper p, .photo__single--prints p {
    width: 70px;
  }
}
@media (max-width: 768px) {
  .photo__single {
    border-bottom: 1px solid #d6d6d6;
    padding-top: 4px;
    max-height: 96px;
    padding: 7px 30px 0 13px;
  }
  .photo__single:first-child {
    border-top: 1px solid #d6d6d6;
  }
  .photo__single--name {
    display: none;
  }
}

.order__box {
  width: 387px;
  height: fit-content;
  position: relative;
  margin-top: 125px;
  padding: 35px 35px 0 35px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.03);
}
.order__box--coupon {
  border-bottom: 1px solid #d6d6d6;
  height: 95px;
  position: relative;
  margin-bottom: 30px;
}
.order__box--coupon input {
  border: 1px solid #d6d6d6;
  background-color: transparent;
  border-radius: 7px;
  height: 35px;
  margin-right: 5px;
  width: 100%;
  z-index: 2;
  padding: 0 10px;
}
@media (max-width: 400px) {
  .order__box--coupon input {
    width: 65%;
  }
}
.order__box--label {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 9px;
}
.order__box--form {
  display: flex;
}
.order__box--form .btn--success .content {
  transition-duration: 0s;
  top: 20px;
  position: absolute;
  right: 0;
}
@media (min-width: 756px) {
  .order__box--form .btn--success .content {
    bottom: 54px;
  }
}
.order__box--form .btn--success .content svg {
  position: absolute;
  right: -120px;
}
@media (min-width: 756px) {
  .order__box--form .btn--success .content svg {
    position: relative;
    right: 12px;
  }
}
.order__box--form .btn--success .content p {
  position: absolute;
  right: 40px;
}
@media (min-width: 756px) {
  .order__box--form .btn--success .content p {
    right: 25px;
  }
}
@media (min-width: 756px) {
  .order__box--form .btn--success .content {
    position: absolute;
    right: 10px;
  }
}
.order__box--form .form__field--text {
  width: 100%;
  border-radius: 7px;
  margin: 0;
}
.order__box--form .form__field--text input {
  background: transparent;
}
.order__box--form .btn {
  margin: 0 0 30px 15px;
  padding: 11px 5px;
  height: 36px;
  width: 100px;
}
.order__box--voucher-info, .order__box--voucher-error {
  position: absolute;
  bottom: 7px;
  color: #b1b1b1;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 0;
}
.order__box--voucher-info span, .order__box--voucher-error span {
  font-weight: 700;
  color: #222;
}
.order__box--voucher-error {
  color: #c45757;
}
.order__box--sum {
  margin-top: 15px;
}
.order__box--sum span {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
}
.order__box--sum span p {
  font-weight: 600;
}
.order__box--sum span:last-child {
  margin-top: 47px;
}
.order__box--sum span:last-child p {
  font-size: 18px;
  font-weight: 700;
}
.order__box .btn--wrapper {
  z-index: 2;
  position: relative;
}
.order__box .btn--wrapper .btn {
  font-weight: 500;
}
.order__box .btn--wrapper .btn--finish {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
}
.order__box .btn--wrapper .btn--finish:after {
  content: "";
  position: absolute;
  right: 10px;
  width: 35px;
  background-image: url("../../Img/arrow-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
@media (max-width: 1080px) {
  .order__box .btn--wrapper .btn--finish {
    max-width: 300px;
    margin: 0 auto 20px auto;
    position: relative;
  }
}
.order__box .btn--wrapper .btn--delivery {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
}
.order__box a {
  text-decoration: none;
}
.order__box--help {
  color: #b1b1b1;
  font-size: 12px;
  text-decoration: underline !important;
  text-align: center;
  z-index: 2;
  display: block;
  position: relative;
}
@media (max-width: 1080px) {
  .order__box {
    width: 100%;
    height: auto;
    padding-bottom: 45px;
  }
}
@media (max-width: 768px) {
  .order__box {
    margin-top: 30px;
  }
}

.order .order__box-delivery {
  padding-bottom: 19px;
}
.order .order__box-delivery .btn--wrapper {
  margin-top: 20px;
}
.order .order__box-delivery .btn--active {
  background-color: #ffc400;
  color: #000;
  pointer-events: all;
}
.order .order__box-delivery .order__box--sum {
  margin-top: 0;
}
.order .order__box-delivery .order__box--sum span {
  height: 26px;
}
.order .order__box-delivery .order__box-delivery {
  text-align: center;
  font-size: 12px;
  color: #ee4c4c;
}
.order .order__box-delivery .order__box--help {
  text-decoration: none;
}
.order .order__box-delivery .order__box-dotpay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.order .order__box-delivery .order__box-dotpay span {
  width: 62px;
}
.order .order__box-delivery .order__box-dotpay span img {
  height: 20px;
}
.order .order__box-delivery .order__box-dotpay a {
  text-decoration: none !important;
}
.order .order__box-delivery .order__box__sumary-delivery {
  margin-top: 10px;
}
.order .order__box-delivery .order__box-delivery-finish {
  color: #b1b1b1;
  font-size: 12px;
  text-align: center;
  margin-top: -8px;
  margin-bottom: 20px;
  display: none;
}

.order__box-delivery.hidden {
  display: none;
}

.order .order__box-delivery-finish.active {
  display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade {
  animation: fade 1s;
}

.order__finish {
  overflow: auto;
}
.order__finish .order__left {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}
@media (max-width: 768px) {
  .order__finish .order__left .btn--wrapper {
    padding: 10px 17px;
  }
}
.order__finish--padding {
  padding: 15px 0;
}

.order__finish .order__left--heading {
  display: block;
}
@media (max-width: 768px) {
  .order__finish .order__left--heading {
    padding: 0 17px;
  }
  .order__finish .order__left--heading h1 {
    padding: 0;
  }
}
.order__finish .order__left h1 {
  font-size: 32px;
}
@media (max-width: 768px) {
  .order__finish .order__left h1 {
    font-size: 26px;
  }
}
@media (max-width: 451px) {
  .order__finish .order__left h1 {
    font-size: 22px;
  }
}
.order__finish .order__left--confirm-payment {
  max-width: 452px;
  font-size: 12px;
  color: #404040;
}
.order__finish .order__left--counter {
  margin-top: 20px;
  font-weight: 600;
  display: block;
}

.order__finish .order__left-box-payment {
  max-width: 550px;
  position: relative;
  padding: 20px;
}
@media (max-width: 768px) {
  .order__finish .order__left-box-payment {
    max-width: unset;
  }
}
.order__finish .order__left-box-payment:after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.0705882353);
  opacity: 31%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.order__finish .order__left-box-payment p {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  align-items: center;
}
.order__finish .order__left-box-payment strong {
  font-weight: 700;
  font-size: 18px;
}
.order__finish .order__left-box-payment img {
  height: 16px;
  margin-top: 3px;
}

.order__payment-status {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
}
.order__payment-status img {
  margin-right: 10px;
}
.order__payment-status--completed {
  color: #09c541;
}
.order__payment-status--rejected {
  color: #c45757;
}
.order__payment-status--processing {
  color: #ffc400;
}

.order-delivery {
  padding: 20px;
  background-color: #f8f8f8;
  max-width: 550px;
}
@media (max-width: 768px) {
  .order-delivery {
    max-width: unset;
  }
}
.order-delivery p {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  line-height: 16px;
}
.order-delivery span {
  font-size: 10px;
  opacity: 51%;
  margin: 10px 0 20px 0;
  display: block;
}
.order-delivery P:nth-child(n+2) {
  font-weight: 500;
  margin: 5px 0;
}
.order-delivery-customer {
  background-color: transparent;
}
.order-delivery-customer p:first-child {
  margin-bottom: 14px;
}

.order__finish .order__left-box-expand {
  display: flex;
  justify-content: center;
}
.order__finish .order__left-box-expand p {
  font-size: 10px;
  opacity: 53%;
  position: relative;
}
.order__finish .order__left-box-expand p:after {
  content: "v";
  position: absolute;
  bottom: -20px;
  left: 60px;
}

.type-filter {
  display: none;
}

.widget-modal {
  width: 100vw !important;
  max-width: 40vw !important;
  max-height: 80vh !important;
}
@media (max-width: 1000px) {
  .widget-modal {
    max-width: 80vw !important;
  }
}
@media (max-width: 800px) {
  .widget-modal {
    max-width: 95vw !important;
    max-height: 95vh !important;
  }
}

.easypack-widget {
  overflow: hidden !important;
}

.search-group-btn {
  height: 60px !important;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.switch-wrapper span {
  cursor: pointer;
}
.switch-wrapper__switch {
  cursor: pointer;
  width: 40px;
  height: 20px;
  margin-left: 10px;
  display: inline-block;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
}
.switch-wrapper__switch_inner {
  width: 20px;
  height: 18px;
  border: 1px solid #fff;
  background-color: #cdcdcd;
  border-radius: 10px;
  transition: 0.1s linear margin-left, background-color;
}
.switch-wrapper__switch--active .switch-wrapper__switch_inner {
  margin-left: 18px;
  background-color: #ffc400;
  border: none;
}

/* Small devices */
/* Medium devices */
/* Large devices */
/* Extra large devices */
/* Extra large devices */
/* Custom breakpoint */
.header {
  padding: 13px 11px;
  height: 65px;
  position: relative;
  background-color: #fff;
}
@media (min-width: 756px) {
  .header {
    height: 76px;
    padding: 28px 32px 10px 32px;
  }
}
.header a {
  z-index: 10;
}
.header__logo {
  width: 97px;
  height: 32px;
}
.header__logo--modified {
  position: fixed;
}
.header__menu {
  display: none;
}
@media (min-width: 756px) {
  .header__menu {
    display: inline-block;
    z-index: 3;
  }
}
.header__link {
  text-decoration: none;
  color: #fff;
  padding: 0 21px;
  font-size: 14pt;
}
.header__link--active {
  font-weight: 700;
}
@media (min-width: 756px) {
  .header__link {
    font-size: 12pt;
  }
}
.header__asset {
  position: absolute;
  top: -100px;
  width: 400px;
  right: -304px;
}
@media (max-width: 1080px) {
  .header__asset {
    display: none;
  }
}
.header__modified .header__inner .header__menu {
  margin-right: 70px;
}
@media (max-width: 1080px) {
  .header__modified .header__inner .header__menu {
    margin-right: 0;
  }
}
.header__inner {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  outline: none;
}
.header__inner .hamburger {
  position: absolute;
  top: 19px;
  right: 29px;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  transition: transform 0.6s 0.1s ease-in-out;
  z-index: 10;
  outline: none;
}
@media (min-width: 756px) {
  .header__inner .hamburger {
    display: none;
  }
}
.header__inner .hamburger--active {
  position: fixed;
}
.header__inner .hamburger--active .hamburger__inner {
  background-color: transparent;
}
.header__inner .hamburger--active .hamburger__inner:before {
  transform: translateY(8px) rotate(45deg);
}
.header__inner .hamburger--active .hamburger__inner:after {
  transform: translateY(-8px) rotate(-45deg);
}
.header__inner .hamburger__box {
  width: 21px;
  height: 15px;
  display: inline-block;
  position: relative;
}
.header__inner .hamburger__inner {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.15s 0.1s ease-in-out;
}
.header__inner .hamburger__inner:before, .header__inner .hamburger__inner:after {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  content: "";
  left: 0;
  transition: transform 0.15s 0.1s ease-in-out;
}
.header__inner .hamburger__inner:before {
  top: -8px;
}
.header__inner .hamburger__inner:after {
  top: 8px;
}

.header__inner--main {
  z-index: 9999;
  background-image: linear-gradient(#ffffff, #f9f9f9);
}
.header__inner--main .header__menu a {
  color: #000;
}

.mobile-nav {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(#ffffff, #f9f9f9);
  z-index: 9;
  height: 0%;
  transition: height 0.2s ease;
}
.mobile-nav nav {
  z-index: 10;
  position: relative;
  visibility: hidden;
  transition: height 0.2s ease;
  height: 0%;
}

.mobile-nav.active {
  height: 100vh;
  position: fixed;
}
.mobile-nav.active nav {
  display: flex;
  justify-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  visibility: visible;
}
.mobile-nav.active .header__link {
  color: gray;
  text-align: center;
  margin: 30px 0;
  font-size: 30px;
  z-index: 10;
}

.footer {
  background-color: #090909;
  bottom: 0;
  display: grid;
  left: 0;
  width: 100%;
  z-index: 1;
  grid-template-columns: 1fr;
}
@media (max-width: 1366px) {
  .footer {
    padding: 20px 40px 80px 60px;
  }
}
@media (max-width: 756px) {
  .footer {
    padding: 340px 40px 87px 40px;
  }
}
@media (min-width: 1000px) {
  .footer {
    grid-template-columns: 1fr 1fr;
    padding: 272px 32px 70px 32px;
  }
}
@media (min-width: 756px) {
  .footer {
    padding: 40px 50px 57px 50px;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
  }
}
@media (min-width: 1024px) {
  .footer {
    position: absolute;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    bottom: -40px;
  }
}
@media (min-width: 1420px) {
  .footer {
    bottom: 0;
    padding: 40px 100px 87px 100px;
  }
}
@media (max-width: 1366px) {
  .footer__icon img {
    height: 76px;
    width: 80px;
  }
}
@media (max-width: 756px) {
  .footer__icon img {
    width: 102px;
    height: 95px;
  }
}
.footer__icon-name {
  color: #ffc400;
  font-size: 22px;
  font-weight: 700;
  margin-top: -60px;
}
@media (max-width: 1366px) {
  .footer__icon-name {
    color: #ffc400;
    font-size: 16px;
    font-weight: 700;
    margin-top: -48px;
  }
}
.footer__icon-descript {
  color: #fff;
  opacity: 0.51;
  font-size: 16px;
  margin-top: -10px;
  margin-right: 20px;
}
@media (max-width: 1366px) {
  .footer__icon-descript {
    font-size: 10pt;
  }
}

.about-us {
  display: flex;
  flex-direction: column;
  position: relative;
}
.about-us__header {
  width: 100%;
  background: linear-gradient(0deg, rgba(246, 246, 246, 0.8549019608), #fff 40%, rgba(236, 236, 236, 0.8549019608));
  height: 254px;
}
.about-us:after {
  content: "";
  position: absolute;
  right: -760px;
  top: 110px;
  width: 100%;
  height: 100%;
  background-image: url("../../../Img/about-us-background.png");
  background-repeat: no-repeat;
}
@media (min-width: 1651px) {
  .about-us:after {
    right: -1060px;
    top: 110px;
  }
}
@media (max-width: 780px) {
  .about-us:after {
    display: none;
  }
}
.about-us__logo {
  width: 314px;
  height: 104px;
  margin-left: 130px;
  margin-top: 120px;
}
@media (max-width: 930px) {
  .about-us__logo {
    margin-left: 20px;
  }
}
.about-us__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.about-us__section {
  display: flex;
  max-width: 830px;
  flex-direction: column;
  padding-left: 140px;
}
@media (max-width: 930px) {
  .about-us__section {
    padding: 0 30px;
  }
}
@media (min-width: 1651px) {
  .about-us__section {
    right: -1060px;
    top: 110px;
  }
}
.about-us__section:last-of-type {
  margin: 80px 0;
}
.about-us__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 28px;
}
.about-us__text {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-us__text p {
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.53);
  max-width: 320px;
  margin-right: 15px;
}
.about-us__big-picture {
  margin-left: -45px;
  z-index: 10;
  padding-left: 140px;
}
@media (max-width: 930px) {
  .about-us__big-picture {
    padding: 0 30px;
  }
}
.about-us__big-title {
  font-size: 84px;
  font-weight: 900;
  line-height: 89px;
  max-width: 750px;
  margin-bottom: 50px;
  padding-left: 140px;
}
@media (max-width: 930px) {
  .about-us__big-title {
    padding: 0 30px;
  }
}
@media (max-width: 571px) {
  .about-us__big-title {
    font-size: 45px;
    line-height: 59px;
  }
}
.about-us__background {
  width: 100%;
  padding: 50px 0;
  background: linear-gradient(0deg, rgba(252, 252, 252, 0.7098039216), #fff 40%, rgba(247, 247, 247, 0.8588235294));
}

.contact {
  display: flex;
  flex-direction: column;
  position: relative;
}
.contact:after {
  content: "";
  position: absolute;
  right: -250px;
  top: -100px;
  width: 850px;
  height: 100%;
  background-image: url("../../../Img/contact-background.png");
  background-repeat: no-repeat;
}
@media (max-width: 1050px) {
  .contact:after {
    right: -400px;
  }
}
@media (max-width: 787px) {
  .contact:after {
    right: -500px;
  }
}
@media (max-width: 678px) {
  .contact:after {
    right: -680px;
  }
}
@media (max-width: 501px) {
  .contact:after {
    display: none;
  }
}
.contact__section {
  width: 100%;
  padding-top: 39px;
  padding-bottom: 110px;
  padding-left: 140px;
}
.contact__section:first-of-type {
  padding-top: 79px;
}
.contact__section:last-of-type {
  padding-bottom: 60px;
}
@media (max-width: 930px) {
  .contact__section {
    padding: 50px 30px;
  }
}
.contact__section--gray {
  background: linear-gradient(0deg, rgba(246, 246, 246, 0.8549019608), #fff 80%, rgba(234, 234, 234, 0.8549019608) 100%);
}
.contact__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 48px;
}
.contact__subtitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.53);
  margin-bottom: 30px;
  max-width: 325px;
}
.contact__form {
  max-width: 315px;
}
.contact__form--field {
  margin-bottom: 14px;
}
.contact__form--field:nth-of-type(4) {
  height: 168px;
}
.contact__form--field .form__field--number, .contact__form--field .form__field--text {
  margin: 0;
}
.contact__form--field .form__data--headingSmall {
  padding: 0;
}
.contact__form--field .form__field--textarea .form__field--label-error {
  top: 3px;
}
.contact__form--field textarea {
  resize: unset;
  width: 100%;
  resize: none;
  height: 120px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  font-family: "Montserrat", sans-serif;
  outline: none;
  padding: 10px;
}
.contact__form .btn--wrapper button {
  float: right;
  padding: 11px 40px;
}
.contact__recaptcha {
  margin-bottom: 30px;
}
.contact__recaptcha div {
  width: 100% !important;
}
.contact__box-wrapper {
  display: flex;
  max-width: 750px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}
.contact__box {
  margin-right: 20px;
  width: 325px;
}
@media (max-width: 749px) {
  .contact__box {
    margin-bottom: 30px;
  }
}
.contact__box--subtitle {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 15px;
}
.contact__box--text {
  color: rgba(0, 0, 0, 0.53);
  font-size: 10px;
  font-weight: 500;
}
.contact__box--text a {
  color: inherit;
  text-decoration: none;
}
.contact__box--text a:hover {
  color: #222;
}
.contact__phone-wrapper {
  display: flex;
  align-items: center;
}
.contact__icon {
  height: 31px;
  width: 31px;
  margin-right: 25px;
}
.contact__number {
  font-size: 33px;
  font-weight: 700;
}
.contact__pdf-box {
  margin-right: 20px;
  width: 325px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  text-decoration: none;
}
.contact__pdf-box--icon {
  margin-top: 5px;
}
.contact__pdf-box--data {
  max-width: 250px;
  flex: 1;
}
@media (max-width: 400px) {
  .contact__pdf-box--data {
    width: 100%;
    margin: 0 15px;
  }
}
.contact__pdf-box--data .title {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #000;
}
.contact__pdf-box--data .description {
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.46);
}

.addPhoto__wrapper {
  position: relative;
  height: 100vh;
}
@media (max-width: 768px) {
  .addPhoto__wrapper {
    background-color: #f9f9f9;
  }
}
.addPhoto__wrapper--hide {
  display: none;
}
.addPhoto__checkout {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
}
.addPhoto__images {
  display: none;
  z-index: 2;
}
@media (min-width: 756px) {
  .addPhoto__images {
    display: block;
    left: -200px;
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .addPhoto__images {
    left: -110px;
  }
}
.addPhoto__images2 {
  display: none;
  z-index: 2;
}
@media (min-width: 756px) {
  .addPhoto__images2 {
    display: block;
    right: -210px;
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .addPhoto__images2 {
    right: -130px;
  }
}
.addPhoto__upload {
  outline: none;
  background-color: transparent;
  transition: 0.2s background-color linear, 0.2s opacity linear, 0.4s filter linear 0.1s;
}
.addPhoto__upload img {
  cursor: pointer;
  width: 170px;
  z-index: 2;
}
@media (min-width: 756px) {
  .addPhoto__upload {
    margin-top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addPhoto__upload--block {
    outline: none;
    position: relative;
    cursor: pointer;
    width: calc(100% - 320px);
    height: calc(100% - 100px);
  }
  .addPhoto__upload--block:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #d7d7d7;
    opacity: 0.1;
    z-index: 10;
    border: 2px dashed #262626;
  }
}
.addPhoto__upload--block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;
  transition: 0.2s background-color linear, 0.2s opacity linear, 0.2s filter linear;
}
.addPhoto__upload--block h1 {
  text-align: center;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  z-index: 2;
}
.addPhoto__upload--block .text,
.addPhoto__upload--block .center {
  visibility: hidden;
}
.addPhoto__upload--block .text {
  opacity: 43%;
}
@media (min-width: 1024px) {
  .addPhoto__upload--block {
    width: calc(100% - 520px);
    height: calc(100% - 149px);
    margin-top: -70px;
  }
}
@media (max-width: 756px) {
  .addPhoto__upload--block {
    padding-top: 50px;
  }
}
.addPhoto__upload--entered {
  background-color: #eeeeee;
  opacity: 0.82;
  filter: blur(4px);
}
@media (min-width: 756px) {
  .addPhoto__upload .text {
    z-index: 2;
    color: #000;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 30px;
    visibility: visible;
    opacity: 0.46;
    margin-bottom: 10px;
  }
  .addPhoto__upload .center {
    display: flex;
    justify-content: center;
    visibility: visible;
  }
  .addPhoto__upload--button {
    outline: none;
    cursor: pointer;
    z-index: 2;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.0705882353);
    border-radius: 20px;
    color: #000;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    padding: 8px 20px;
    text-decoration: none;
  }
  .addPhoto__upload--button:hover {
    background-color: #e2e2e2;
  }
  .addPhoto__upload--button:before {
    align-items: center;
    content: "+";
    color: #ffc400;
    display: flex;
    font-size: 45px;
    font-weight: 300;
    height: 30px;
    margin-right: 13px;
    width: 30px;
  }
}
.addPhoto__entered-icon {
  position: absolute;
  z-index: 11;
}
.addPhoto__entered-icon img {
  width: 150px;
}

.dropzone-area {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  z-index: 100;
  outline: none;
  transition: 0.2s background-color linear, 0.2s opacity linear;
}
.dropzone-area--entered {
  opacity: 0.82;
  background-color: #eeeeee;
}
.dropzone-area__entered-icon {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.photo-loader {
  background-color: #f5f5f5;
}

.selection-mode {
  border: 3px solid transparent;
  overflow: hidden;
  box-sizing: content-box;
  transition: transform 0.15s ease-out;
}
.selection-mode--active {
  border-radius: 5px;
  border: 4px solid #ffc400;
}
.selection-mode:hover {
  transform: scale(1.03);
  transition: transform 0.15s ease-in-out;
}

.progress {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 120;
}
.progress__wrapper-bar {
  height: 8px;
  margin: 0;
  width: 70%;
  background-color: #fff;
  border-radius: 10px;
}
.progress__bar {
  background-color: #ff0099;
  height: 8px;
  width: 0px;
  margin: 0;
  border-radius: 10px;
  position: relative;
  transition: width 0.3s ease-out;
}

.svg--chevron-left .a {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
}
.svg--frame {
  fill: #fff;
}
.svg--select .a > .b {
  fill: none;
  stroke-linecap: round;
}
.svg--select .b,
.svg--select .c {
  fill: #000;
  stroke: none;
}
.svg--cart .a {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
}
.svg--times .a {
  fill: #c2c2c2;
}
.svg--success .a {
  fill: rgb(1, 187, 88);
}
.svg--delete .a {
  fill: #ff002b;
}
.svg--phone .a {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
  opacity: 0.12;
}
.svg--download .a {
  opacity: 0.17;
}

input::placeholder {
  color: #cecfd0;
  font-size: 13px;
}

.form__field {
  position: relative;
  height: 37px;
}
.form__field .field-label {
  transform: translateY(-14px);
  font-size: 0.6rem;
  position: absolute;
  left: 30px;
  font-weight: 300;
  color: #aeaeae;
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.form__field .select-label {
  position: absolute;
  left: 30px;
  top: 0px;
  font-weight: 300;
  color: #aeaeae;
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.form__field .select-label--focus {
  transform: translateY(-14px);
  font-size: 0.6rem;
}
.form__field .react-select__control {
  border-style: unset;
  min-height: 40px;
  height: 24px;
  font-size: 13px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e9ec;
  color: #4d4f5c;
  padding: 0px 0 0px 12px;
  font-weight: 300;
  border-radius: 4px;
}
.form__field .react-select__control:hover {
  border-color: #808495;
}
.form__field .react-select__control--is-focused {
  outline: none;
  box-shadow: none;
  height: 24px;
  border: 1px solid #3b86ff;
}
.form__field .react-select__control--is-focused:hover {
  border-color: #3b86ff;
}
.form__field .react-select--is-multi__control {
  border-style: unset;
  min-height: 40px;
  height: 24px;
  font-size: 13px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e9ec;
  color: #4d4f5c;
  padding: 0px 0 0px 12px;
  font-weight: 300;
  border-radius: 4px;
}
.form__field .react-select--is-multi__control:hover {
  border-color: #808495;
}
.form__field .react-select--is-multi__control:focus {
  border-color: #3b86ff;
  border: 1px solid #3b86ff;
}
.form__field .react-select--is-multi__control--is-focused {
  outline: none;
  box-shadow: none;
  border: 1px solid #3b86ff;
}
.form__field .react-select--is-multi__value-container {
  padding: 0;
}
.form__field .react-select--is-multi__menu {
  text-align: left;
}
.form__field .react-select__menu {
  text-align: left;
}
.form__field .react-select__value-container {
  padding: 0;
  min-height: 24px;
}
.form__field .react-select__indicators {
  position: relative;
}
.form__field .react-select__placeholder {
  color: #cecfd0;
  font-size: 13px;
}
.form__field .react-select__single-value {
  color: #4d4f5c;
  font-weight: 300;
  font-size: 13px;
  overflow: visible;
}
.form__field--error input,
.form__field--error textarea,
.form__field--error .react-select__control,
.form__field--error .react-select--is-multi__control {
  border: 1px solid #ff6565 !important;
}
.form__field--error .react-select__input input,
.form__field--error .react-select--is-multi__control input {
  border: none !important;
}
.form__field--checkbox {
  height: 20px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  margin: -10px auto 25px auto;
  font-size: 14px;
  color: #969090;
}
.form__field--disabled input[type=text]::placeholder {
  color: #dcdbdb;
}
.form__field input,
.form__field select {
  font-size: 13px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e9ec;
  outline: none;
  color: #4d4f5c;
  padding: 10px 0 10px 12px;
  font-weight: 300;
  border-radius: 4px;
}
.form__field input option,
.form__field select option {
  color: #444;
}
.form__field input:hover,
.form__field select:hover {
  cursor: default;
}
.form__field input[type=checkbox] {
  width: 20px;
  height: 17px;
}
.form__field .verify-success {
  margin-right: 5px;
  color: #0dbb0d;
}
.form__field .verify-pending {
  margin-right: 5px;
  color: #f9a101;
}
.form__field i {
  position: absolute;
  left: -5px;
  top: 0;
  font-size: 1.2rem;
  color: #cccbcb;
  height: 23.5px;
  display: flex;
  align-items: center;
}
.form__field--label-error {
  position: relative;
  top: 9px;
  text-align: right;
  font-size: 11px;
  font-weight: 500;
  color: #ff6565;
  height: 13px;
}
.form__field--label-error span {
  position: relative;
  animation: show 0.25s ease-in;
}
@keyframes show {
  0% {
    right: -40px;
  }
  50% {
    right: 0px;
  }
  80% {
    right: -10px;
  }
  100% {
    right: 0px;
  }
}