.switch-wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: black;
  span {
    cursor: pointer;
  }
  &__switch {
    cursor: pointer;
    width: 40px;
    height: 20px;
    margin-left: 10px;
    display: inline-block;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    &_inner {
      width: 20px;
      height: 18px;
      border: 1px solid #fff;
      background-color: #cdcdcd;
      border-radius: 10px;
      transition: 0.1s linear margin-left, background-color;
    }
    &--active {
      .switch-wrapper__switch_inner {
        margin-left: 18px;
        background-color: $yellow;
        border: none;
      }
    }
  }
}
