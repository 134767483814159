.type-filter {
  display: none;
}

.widget-modal {
  width: 100vw !important;
  max-width: 40vw !important;
  max-height: 80vh !important;
  @media (max-width: 1000px) {
    max-width: 80vw !important;
  }
  @media (max-width: 800px) {
    max-width: 95vw !important;
    max-height: 95vh !important;
  }
}
.easypack-widget {
  overflow: hidden !important;
}
.search-group-btn {
  height: 60px !important;
}

// .widget-modal .search-widget .search-group-btn:not(.with-filters) {
//   width: 1% !important;
// }
