.action-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  &__title {
    margin: 20px 0 0 20px;
    color: $white;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
    @media (max-width: 385px) {
      margin: 20px 0 0 0;
    }
  }
  &--r,
  &--l {
    display: flex;
    flex-wrap: wrap;
  }
  &--r {
    // .content {
    //   width: 60px;
    // }
    .yellow-icon {
      .svg--select .b,
      .svg--select .c {
        fill: $yellow;
      }
      .svg--select .a > .b {
        fill: none;
      }
    }
    .btn-basket {
      .content {
        width: 80px;
        white-space: nowrap;
      }
      padding-right: 21px;
      // @media (min-width: 800px) {
      //   padding-right: 21px;
      // }
    }
    .btn--wrapper {
      padding-right: 13px;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  .btn--wrapper {
    margin-top: 20px;
  }
  &--l {
    .btn--wrapper {
      padding-right: 13px;
    }
  }
  &--customizationPanel {
    margin-bottom: 10px;
  }
}
