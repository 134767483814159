body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
.main-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  &--outerBannerVisible {
    min-height: 100vh;
    // overflow: hidden; iPhone
    display: flex;
    .page {
      &__inner {
        width: 100%;
        position: relative;
        // @media (max-width: 1000px) {
        //   width: 100%;
        // }
        @media (max-width: 756px) {
          width: 100%;
        }
      }
    }
    .outerBanner {
      width: 35%;
      // margin-bottom: -13px; iPhone
      // overflow-y: scroll; iPhone
      // flex-shrink: 0; iPhone

      // animation: fadeIn2 0.15s;
      // transition: all 0.25s;
      @media (max-width: 1200px) {
        width: 100%;
      }
      // &--visible {
      //   transition: all 0.25s;
      // }
      // &--hidden {
      //   // display: none;
      //   transition: all 0.25s;
      //   width: 0;
      // }
    }
  }
  &--100vh {
    height: 100vh !important;
  }
}

.main-wrapper--outerBannerVisible {
  // box-shadow: inset 0 0 200px #000000, inset 0 0 200px #000000;
  .photo-prints {
    display: none;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    // height: 100%;

    .screen--photo-print {
      min-height: calc(100vh - 120px);
      @media (max-width: 1200px) {
        height: 0;
        min-height: calc(100vh - 120px);
      }
    }

    .sliderGallery--nav,
    .customizePhoto__heading,
    .customizePhotoFooter__summary--forEach,
    .customizationPanelFooter--r .btn__icon-placement--right {
      display: none;
    }
    .customizePhotoFooter {
      order: 3;
      padding: 0;
      margin-top: 0;
      margin-top: 30px;
    }
    .customizePhoto__offer {
      order: 2;
    }
    .customizePhoto__inner {
      padding-top: 0;
    }
    .counter__btn {
      padding: 10px 5px;
    }
    .customizationPanel .container {
      padding: 0;
    }
    .customizePhoto__offer--paperType {
      margin: 0;
      padding-bottom: 0;
    }
    .customizePhoto img {
      display: none;
    }
    .action-bar {
      flex-wrap: wrap !important;
    }
  }
  .page__inner {
    // filter: unset;
    &.blurred {
      transition: filter 0.2s ease-out, opacity 0.2s;
    }
    transition: opacity 0.2s;
    // -webkit-transition: none !important;
    // -moz-transition: none !important;
    // -o-transition: none !important;
    // transition: none !important;
  }

  .outerBanner {
    // width: 100%;
    z-index: 999;
    &--mg-80 {
      margin-top: 80px;
    }
  }
  // .sliderGallery--nav {
  //   display: none;
  // }
}
.blurred {
  @media (min-width: 1200px) {
    .header {
      opacity: 0.3;
      filter: blur(10px);
    }
  }
  .screen--photo-print {
    opacity: 0.3;
    filter: blur(10px);
  }
}
.shadowed {
  box-shadow: 0px 4px 21px #dadada;
}
@keyframes fadeIn2 {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}
