.photo-prints {
  // @extend .container;
  position: relative;
  margin-top: 20px;
  // overflow: auto;
  padding-bottom: 70px;
  &--unscrollable {
    overflow: hidden;
  }
  &__gallery-wrapper {
    transition: 0.4s filter linear;
    &--blurred {
      filter: blur(4px) brightness(1.2);
    }
  }
  @media (min-width: 1025px) {
    min-height: 100vh;
  }
  @media (max-width: 756px) {
    height: auto;
  }
}

.screen--photo-print {
  .action-bar {
    // flex-wrap: wrap;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 530px) {
    .btn--wrapper:first-child {
      padding-left: 0;
    }
  }

  .btn--basket {
    display: none;
    @media (max-width: 756px) {
      display: block;
      position: absolute;
      bottom: 50px;
      right: 17px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      background-image: url('../../Img/icon-basket.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.customizationPanel {
  position: absolute;
  left: 0;
  top: 65px;
  right: 0;
  background-color: $black;
  min-height: calc(100vh - 60px);
  // padding: 0 20px;
  padding: 0;
  z-index: 102;
  @include md {
    top: 75px;
  }
  @media (max-width: 1200px) {
    height: auto;
    min-height: auto;
    padding-bottom: 30px;
    bottom: 0;
  }
  .container {
    position: relative;
  }
  &__framing-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
    @media (max-width: 485px) {
      flex-direction: column;
      &--l,
      &--r {
        justify-content: space-between;
        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
    &--l,
    &--r {
      display: flex;
    }
    &--l {
      .btn--wrapper {
        &:first-of-type {
          margin-right: 13px;
        }
      }
    }
  }
  &__framing {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
  }
}
.customizationPanelFooter {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0 10px;
  // margin-top: -50px;
  // @media (min-width: 1000px) {
  //   margin-top: 0;
  // }
  &__actions {
    display: flex;
    align-items: center;
  }
  .image-name {
    font-size: 12px;
  }
  &--l {
    .btn--wrapper {
      padding-right: 15px;
    }
  }
  &--r {
    .btn--wrapper {
      padding-left: 15px;
    }
  }
}
.sliderGallery {
  img {
    max-width: 100%;
    max-height: calc(100vh - 330px);
    object-fit: contain;
  }
  &--single {
    height: calc(100vh - 300px);
    &__item_inner {
      height: calc(100vh - 330px);
      margin-top: 10px;
    }
    &__item {
      outline: none;
      img {
        margin: 0 auto;
      }
      &_inner {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &--active-item {
    border-color: $yellow !important;
  }
  &--nav__item {
    outline: none;
  }
  // .slick-list {
  //   max-height: 120px;
  // }
  .slick-slide {
    cursor: pointer;
    .sliderGallery--nav__item_inner {
      border: 2px solid transparent;
    }
    // &.slick-active {
    //   .sliderGallery--nav__item_inner {
    //     border-color: $yellow;
    //   }
    // }
  }
  &--nav {
    &__item {
      padding: 5px;
      img {
        margin: 0 auto;
        max-width: 200px;
        min-width: 80px;
        object-fit: contain;
      }
    }
  }
}

.react-photo-gallery--gallery > div > img {
  z-index: 101;
  object-fit: contain;
  // &:hover {
  //   border-radius: 30px;
  //   z-index: 103;
  // }
}

.react-photo-gallery--gallery {
  margin-bottom: 100px;
  padding: 0 5px;
  @media (max-width: 1200px) {
    margin-bottom: 0;
    // max-height: 300px;
  }
}

.selectedPhotosGallery {
  overflow: auto;
  // padding-bottom: 100px; iPhone
  padding-bottom: 160px;
  height: 100vh;
  background-color: #000;
}
