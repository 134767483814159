.form__delivery {
  display: flex;
  flex-direction: column;
  // max-width: 631px;
  margin-top: 30px;
  padding-right: 20px;

  // max-height: 400px;
  &-check {
    width: 30px;
    height: 30px;
    transform: scale(1.2);
    outline: none;
    margin-right: 15px;
  }
  &__single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 19px 20px;
    margin: 10px 0;
    cursor: pointer;
    &--l {
      display: flex;
      input {
        display: none;
      }
    }
    &:hover {
      background-color: #f8f8f8;
      border-radius: 7px;
    }
  }
  &--group {
    max-width: 631px;
  }

  &--info {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 20px 0 80px 0;
  }
  &--switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 45px 0;
    width: 100%;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
    @media (max-width: 768px) {
      padding: 40px 20px 45px 20px;
    }
  }
  &--data1 {
    // margin-right: 140px;
    max-width: 300px;
    width: 100%;
    padding-bottom: 10px;
    @media (min-width: 769px) {
      margin-right: 40px;
    }
    // @media (max-width: 599px) {
    //   max-width: unset;
    //   .form__field--number,
    //   .form__field--text,
    //   .form__field--number input,
    //   .form__field--text input {
    //     max-width: unset;
    //   }
    // }
  }
  &--data2 {
    max-width: 300px;
    width: 100%;
  }
  &--data3 {
    max-width: 100%;
    width: 100%;
  }
  &--data1,
  &--data2 {
    @media (max-width: 599px) {
      max-width: unset;
      .form__field--number,
      .form__field--text,
      .form__field--number input,
      .form__field--text input {
        max-width: unset;
      }
    }
  }
  @media (max-height: 800px) {
    max-height: 180px;
  }
  @media (max-width: 1080px) {
    height: 100%;
    max-height: 100%;
    padding-right: 0;
    &--group {
      max-width: unset;
    }
  }
}
.form__delivery__single {
  &--l p {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    span {
      display: block;
      font-size: 10px;
      // opacity: 53%;
      position: relative;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &--r {
    font-size: 13px;
    font-weight: 700;
    margin-left: 8px;
    flex-shrink: 0;
  }
  // @media (max-width: 768px) {
  //   margin: 0 30px;
  // }
}

.form__data {
  &--choose-place {
    padding: 20px 20px;
    padding-right: 50px;
    border-radius: 7px;
    background-color: #f8f8f8;
    font-size: 13px;
    font-weight: 500;
    max-width: 683px;
    position: relative;
    cursor: pointer;
    color: rgb(161, 161, 161);
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 35px;
      background-image: url('../../Img/arrow-bottom.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }
  }
  &--choose-bottom {
    font-size: 13px;
    font-weight: 500;
    opacity: 31%;
    padding-left: 26px;
  }
  &--heading {
    margin: 30px 0;
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 768px) {
      padding: 0 17px;
    }
  }
  &--headingSmall {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 9px;
    @media (max-width: 768px) {
      padding: 0 30px;
    }
  }

  &--small-info {
    margin-top: 7px;
    color: #000;
    opacity: 46%;
    font-size: 10px;
    @media (max-width: 768px) {
      padding: 0 30px;
    }
  }
  &--tel-prefix {
    width: 61px;
  }
  &-tel-number {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    p {
      width: 100%;
      // margin-left: 75px;
      margin-top: -5px;
    }

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      margin-bottom: 20px;
      position: relative;
      div {
        margin: 0;
      }
      p {
        padding: 0;
        position: absolute;
        bottom: -30px;
        width: auto;
      }
      padding: 0 30px;
    }
  }
  &--tel-number {
    margin-left: 8px;
    max-width: 246px;
    width: 100%;
  }
}

.form__field--number,
.form__field--text {
  max-width: 315px;
  input {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    height: 35px;
    max-width: 315px;
    width: 100%;
    padding-left: 10px;
    position: relative;
  }
  input[name='telPrefix'] {
    width: 61px;
  }
  @media (max-width: 768px) {
    margin: 0 30px;
  }
}
.form__field--label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.form__field--icon {
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    // background-color: transparent;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 50%;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    // vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
}
.form__field--checkbox {
  margin-right: 20px;
  input {
    cursor: pointer;
    display: none;
  }
}
input:checked + .form__field--icon:after {
  content: '';
  display: block;
  // position: absolute;
  // top: 6px;
  // left: 8px;
  // margin-left: 8px;
  // margin-top: -15px;
  width: 23px;
  height: 20px;
  // border: solid #000;
  // border-width: 0 1px 1px 0;
  // transform: rotate(45deg);

  background-image: url('../../Img/checkbox-active.svg');
  background-repeat: no-repeat;
}
.form__field--icon-active:after {
  content: '';
  display: block;
  // position: absolute;
  // top: 6px;
  // left: 8px;
  // margin-left: 8px;
  // margin-top: -15px;
  width: 23px;
  height: 20px;
  // border: solid #000;
  // border-width: 0 1px 1px 0;
  // transform: rotate(45deg);
  background-image: url('../../Img/checkbox-active.svg');
  background-repeat: no-repeat;
}

input:checked + .form__field--icon label {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 7px;
  cursor: pointer;
}
.form__field--icon-active label {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 7px;
  cursor: pointer;
}

input:checked + .form__field--icon:before {
  display: none;
  background-color: #fec500;
  border: 0;
  padding: 9px;
}

.form__field--icon-active:before {
  display: none;
  background-color: #fec500;
  border: 0;
  padding: 9px;
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type='number'] {
//   -moz-appearance: textfield;
// }
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form {
  &__switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    @media (max-width: 599px) {
      width: 100%;
    }
  }
}
