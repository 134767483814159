.dropzone-area {
  position: absolute;
  width: 100%;
  height: 100%;
  // min-height: calc(100vh -76px);
  min-height: 100%;
  top: 0;
  z-index: 100;
  outline: none;
  transition: 0.2s background-color linear, 0.2s opacity linear;
  &--entered {
    // border: 2px dashed #707070;
    // background-color: #eeeeee;
    opacity: 0.82;
    background-color: #eeeeee;
  }
  &__entered-icon {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
