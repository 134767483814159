.svg {
  &--chevron-left {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
    }
  }
  &--frame {
    fill: #fff;
  }
  &--select {
    .a > .b {
      fill: none;
      stroke-linecap: round;
    }
    .b,
    .c {
      fill: #000;
      stroke: none;
    }
  }
  &--cart {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3px;
    }
  }
  &--times {
    .a {
      fill: #c2c2c2;
    }
  }
  &--success {
    .a {
      fill: rgb(1, 187, 88);
    }
  }
  &--delete {
    .a {
      fill: #ff002b;
    }
  }
  &--phone {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3px;
      opacity: 0.12;
    }
  }
  &--download {
    .a {
      opacity: 0.17;
    }
  }
}
