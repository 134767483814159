@mixin leftPadding {
  padding-left: 140px;
  @media (max-width: 930px) {
    padding: 0 30px;
  }
}

.about-us {
  display: flex;
  flex-direction: column;
  position: relative;
  &__header {
    width: 100%;
    background: linear-gradient(0deg, #f6f6f6da, #fff 40%, #ecececda);
    height: 254px;
  }
  &:after {
    content: '';
    position: absolute;
    right: -760px;
    top: 110px;
    width: 100%;
    height: 100%;
    background-image: url('../../../Img/about-us-background.png');
    background-repeat: no-repeat;
    @media (min-width: 1651px) {
      right: -1060px;
      top: 110px;
    }

    @media (max-width: 780px) {
      display: none;
    }
  }
  &__logo {
    width: 314px;
    height: 104px;
    margin-left: 130px;
    margin-top: 120px;
    @media (max-width: 930px) {
      margin-left: 20px;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }
  &__section {
    display: flex;
    max-width: 830px;
    flex-direction: column;

    @include leftPadding;
    @media (min-width: 1651px) {
      right: -1060px;
      top: 110px;
    }
    &:last-of-type {
      margin: 80px 0;
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 28px;
  }
  &__text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      font-size: 11px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.53);
      max-width: 320px;
      margin-right: 15px;
    }
  }
  &__big-picture {
    margin-left: -45px;
    z-index: 10;
    @include leftPadding;
  }
  &__big-title {
    font-size: 84px;
    font-weight: 900;
    line-height: 89px;
    max-width: 750px;
    margin-bottom: 50px;
    @include leftPadding;
    @media (max-width: 571px) {
      font-size: 45px;
      line-height: 59px;
    }
  }
  &__background {
    width: 100%;
    padding: 50px 0;
    background: linear-gradient(0deg, #fcfcfcb5, #fff 40%, #f7f7f7db);
  }
}
