.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // min-height: 900px;
  @media (max-width: 1224px) {
    // height: 100%; iPhone
    // min-height: 0; iPhone
  }
}

.landing-page {
  @media (max-width: 1224px) {
    height: 970px;
  }
  @media (max-width: 756px) {
    height: auto;
  }
  h1 {
    font-weight: 900;
    line-height: 37pt;
    // margin-left: 20%;
    margin-top: 100px;
    position: relative;
    z-index: 3;
    margin-left: 84px;
    strong {
      color: $yellow;
    }
    @include sm {
      font-size: 38pt;
    }
    @include md {
      left: 80px;
    }

    @include lg {
      position: absolute;
      left: 210px;
      margin: 0%;
      font-size: 70px;
      line-height: 58pt;
      top: 28%;
    }
    // @media(max-width: 1366px) {
    //   font-size: 50pt;
    //   line-height: 54pt;
    // }
    @include xl {
      line-height: 70pt;
      font-size: 84px;
      top: 20%;
    }
    @media (max-height: 650px) and (min-width: 1000px) {
      margin-top: -30px;
    }
  }

  &--img {
    position: absolute;
    top: 0;
    margin-top: -350px;
    @media (max-height: 650px) and (min-width: 1000px) {
      margin-top: -400px;
    }
  }
  &--link {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  &__creator-link {
    font-family: $fontMain;
    align-items: center;
    background-color: $yellow;
    border: 0;
    border-radius: 7px;
    box-shadow: 0pt 3pt 30pt #00000052;
    cursor: pointer;
    color: $black;
    display: flex;
    font-weight: 700;
    padding: 10pt 15pt;
    font-size: 17px;
    // margin-left: 20%;
    position: relative;
    z-index: 3;
    margin-bottom: 200px;
    margin-top: 170px;
    @include sm {
      font-size: 17px;
      padding: 11pt 15pt;
    }
    @include md {
      position: absolute;
      left: 65%;
      bottom: 47%;
      font-size: 13px;
      margin-bottom: 0;
    }
    @include lg {
      left: 70%;
    }
    @include xl {
      bottom: 50%;
      font-size: 17px;
      margin: 0%;
      z-index: 2;
    }

    &:after {
      content: '';
      background-image: url('../../Img/arrow.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 15px;
      height: 22px;
      margin-left: 30px;
    }
  }
  .bg {
    z-index: 2;
    margin-left: -80px;
    margin-top: -100px;
    width: 1200px;
    @include md {
      right: 0;
      margin-right: -370px;
      margin-top: -300px;
      width: 1550px;
    }
    @include xl {
      margin-right: -350px;
    }
  }
  .frame {
    width: 1000px;
    left: -820px;
    top: 10%;
    z-index: 3;

    @include md {
      left: -880px;
      width: 1148px;
    }
    @include xl {
      left: -1080px;
      top: -8%;
      width: 1435px;
    }
  }
}
