@import '../../settings';
@import '../../breakpoints';

@mixin hamburger-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
}
.header {
  padding: 13px 11px;
  height: 65px;
  position: relative;
  background-color: #fff;
  @include md {
    height: 76px;
    padding: 28px 32px 10px 32px;
  }
  a {
    z-index: 10;
  }
  &__logo {
    width: 97px;
    height: 32px;
    &--modified {
      position: fixed;
    }
  }
  &__menu {
    display: none;
    @include md {
      display: inline-block;
      z-index: 3;
    }
  }
  &__link {
    text-decoration: none;
    color: #fff;
    padding: 0 21px;
    font-size: 14pt;
    &--active {
      font-weight: 700;
    }
    @include md {
      font-size: 12pt;
    }
    // @include xl {
    //   font-size: 14pt;
    // }
  }

  &__asset {
    position: absolute;
    top: -100px;
    width: 400px;
    right: -304px;
    @media (max-width: 1080px) {
      display: none;
    }
  }
  &__modified {
    .header__inner .header__menu {
      // margin-right: 36%;
      margin-right: 70px;
      @media (max-width: 1080px) {
        margin-right: 0;
      }
    }
  }

  &__inner {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    outline: none;
    .hamburger {
      position: absolute;
      top: 19px;
      right: 29px;
      padding: 0;
      display: inline-block;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      margin: 0;
      transition: transform 0.6s 0.1s ease-in-out;
      z-index: 10;
      outline: none;
      @include md {
        display: none;
      }
      &--active {
        position: fixed;
        .hamburger__inner {
          background-color: transparent;
          &:before {
            transform: translateY(8px) rotate(45deg);
          }
          &:after {
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }
      &__box {
        width: 21px;
        height: 15px;
        display: inline-block;
        position: relative;
      }
      &__inner {
        @include hamburger-line;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color 0.15s 0.1s ease-in-out;
        &:before,
        &:after {
          @include hamburger-line;
          content: '';
          left: 0;
          transition: transform 0.15s 0.1s ease-in-out;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }
    }
  }
}

.header__inner--main {
  z-index: 9999;
  background-image: linear-gradient(#ffffff, #f9f9f9);
  // background-image: linear-gradient(0deg, #ffffff, #f9f9f9);
  .header__menu a {
    color: #000;
  }
}

.mobile-nav {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(#ffffff, #f9f9f9);
  // background-color: blue;
  z-index: 9;
  height: 0%;
  transition: height 0.2s ease;
  nav {
    z-index: 10;
    position: relative;
    visibility: hidden;
    transition: height 0.2s ease;
    height: 0%;
  }
}
.mobile-nav.active {
  height: 100vh;
  position: fixed;
  nav {
    display: flex;
    justify-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    visibility: visible;
  }
  .header__link {
    color: gray;
    text-align: center;
    margin: 30px 0;
    font-size: 30px;
    z-index: 10;
  }
}
