.photo-loader {
  background-color: #f5f5f5;
}
.selection-mode {
  border: 3px solid transparent;
  overflow: hidden;
  box-sizing: content-box;
  transition: transform 0.15s ease-out;
  &--active {
    border-radius: 5px;
    border: 4px solid $yellow;
  }
  &:hover {
    transform: scale(1.03);
    transition: transform 0.15s ease-in-out;
    // border: none;
  }
}
