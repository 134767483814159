.addPhoto {
  &__wrapper {
    position: relative;
    height: 100vh;
    @media (max-width: 768px) {
      // height: calc(100vh - 90px);
      background-color: #f9f9f9;
    }
    &--hide {
      display: none;
    }
  }
  &__checkout {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
  }
  &__images {
    display: none;
    // left: -680px;
    // top: -380px;
    z-index: 2;
    @include md {
      display: block;
      left: -200px;
      position: absolute;
    }
    @include lg {
      left: -110px;
    }
    // @include xl {
    //   left: -680px;
    //   top: -200px;
    // }
  }
  &__images2 {
    display: none;
    // right: -680px;
    // top: -380px;
    z-index: 2;
    @include md {
      display: block;
      right: -210px;
      position: absolute;
    }
    @include lg {
      right: -130px;
    }
    // @include xl {
    //   right: -840px;
    //   top: -300px;
    // }
  }
  &__upload {
    outline: none;
    img {
      cursor: pointer;
      width: 170px;
      z-index: 2;
    }
    @include md {
      margin-top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &--block {
        outline: none;
        position: relative;
        cursor: pointer;
        width: calc(100% - 320px);
        height: calc(100% - 100px);
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          // background-color: #eeeeee;
          background-color: #d7d7d7;
          opacity: 0.1;
          z-index: 10;
          // border: 2px dashed #707070;
          // border: 2px dashed #acacac;
          border: 2px dashed #262626;
        }
      }
    }
    &--block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      position: relative;
      h1 {
        text-align: center;
        color: #000000;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        z-index: 2;
      }
      .text,
      .center {
        visibility: hidden;
      }
      .text {
        opacity: 43%;
      }
      @include lg {
        width: calc(100% - 520px);
        height: calc(100% - 149px);
        margin-top: -70px;
      }
      @media (max-width: 756px) {
        padding-top: 50px;
      }
      transition: 0.2s background-color linear, 0.2s opacity linear, 0.2s filter linear;
    }
    background-color: transparent;
    transition: 0.2s background-color linear, 0.2s opacity linear, 0.4s filter linear 0.1s;
    &--entered {
      background-color: #eeeeee;
      opacity: 0.82;
      filter: blur(4px);
    }

    @include md {
      .text {
        z-index: 2;
        color: #000;
        text-align: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 30px;
        visibility: visible;
        opacity: 0.46;
        margin-bottom: 10px;
      }
      .center {
        display: flex;
        justify-content: center;
        visibility: visible;
      }
      &--button {
        outline: none;
        cursor: pointer;
        z-index: 2;
        align-items: center;
        background-color: #00000012;
        border-radius: 20px;
        color: #000;
        display: flex;
        font-size: 10px;
        font-weight: 700;
        padding: 8px 20px;
        text-decoration: none;
        &:hover {
          background-color: #e2e2e2;
        }
        &:before {
          align-items: center;
          content: '+';
          color: #ffc400;
          display: flex;
          font-size: 45px;
          font-weight: 300;
          height: 30px;
          margin-right: 13px;
          width: 30px;
        }
      }
    }
  }
  &__entered-icon {
    position: absolute;
    z-index: 11;
    img {
      width: 150px;
    }
  }
}
