.progress {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 120;
  &__wrapper-bar {
    height: 8px;
    margin: 0;
    width: 70%;
    background-color: #fff;
    border-radius: 10px;
  }
  &__bar {
    background-color: #ff0099;
    height: 8px;
    width: 0px;
    margin: 0;
    border-radius: 10px;
    position: relative;
    transition: width 0.3s ease-out;
  }
}
