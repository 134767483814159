.order__finish {
  overflow: auto;
  .order__left {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    @media (max-width: 768px) {
      .btn--wrapper {
        padding: 10px 17px;
      }
    }
  }
  &--padding {
    padding: 15px 0;
  }
}
.order__finish .order__left {
  &--heading {
    display: block;
    @media (max-width: 768px) {
      padding: 0 17px;
      h1 {
        padding: 0;
      }
    }
  }
  h1 {
    font-size: 32px;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 26px;
    }
  }
  @media (max-width: 451px) {
    h1 {
      font-size: 22px;
    }
  }
  &--confirm-payment {
    max-width: 452px;
    font-size: 12px;
    color: #404040;
  }
  &--counter {
    margin-top: 20px;
    font-weight: 600;
    display: block;
  }
}
.order__finish .order__left-box-payment {
  // max-width: 518px;
  max-width: 550px;
  position: relative;
  padding: 20px;
  @media (max-width: 768px) {
    max-width: unset;
  }
  &:after {
    content: '';
    position: absolute;
    background-color: #00000012;
    opacity: 31%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  p {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
  }
  strong {
    font-weight: 700;
    font-size: 18px;
  }
  img {
    height: 16px;
    margin-top: 3px;
  }
}
.order {
  &__payment-status {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    img {
      margin-right: 10px;
    }
    &--completed {
      color: #09c541;
    }
    &--rejected {
      color: #c45757;
    }
    &--processing {
      color: $yellow;
    }
  }
}
.order-delivery {
  padding: 20px;
  background-color: #f8f8f8;
  max-width: 550px;
  @media (max-width: 768px) {
    max-width: unset;
  }
  p {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    line-height: 16px;
  }
  span {
    font-size: 10px;
    opacity: 51%;
    margin: 10px 0 20px 0;
    display: block;
  }
  P:nth-child(n + 2) {
    font-weight: 500;
    margin: 5px 0;
  }
  &-customer {
    background-color: transparent;
    p:first-child {
      margin-bottom: 14px;
    }
  }
}

.order__finish .order__left-box-expand {
  display: flex;
  justify-content: center;
  p {
    font-size: 10px;
    opacity: 53%;
    position: relative;
    &:after {
      content: 'v';
      position: absolute;
      bottom: -20px;
      left: 60px;
    }
  }
}
