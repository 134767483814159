.checkout {
  // @extend .customizePhoto;
  // background-repeat: no-repeat;
  color: $black;
  // background-size: 60%;
  // background-position: 214% -16%;
  position: relative;

  background-color: #fff;
  @media (max-width: 1200px) {
    // position: fixed;
    position: absolute;
    // top: 76px;
    top: 65px;
    width: 100%;
    background-color: #fff;
    height: 100%;
  }

  &__inner {
    animation: fadeIn 0.1s;
    // padding: 70px;
    padding: 70px 70px 70px 40px;
    min-height: 100vh;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    background-color: #fff;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    @media (max-width: 1440px) {
      padding: 70px 30px 50px 30px;
    }
    @media (min-width: 1201px) {
      height: 100vh;
    }
    @media (max-width: 1240px) {
      padding: 70px 30px 50px 10px;
    }
    @media (max-width: 1046px) {
      padding-left: 0;
    }
    @media (max-width: 1200px) {
      padding: 30px 50px 30px 30px;
      max-height: calc(100vh - 170px);
      // max-height: calc(100vh - 240px);
      min-height: auto;
      // overflow: scroll;
      // height: auto;
      overflow: hidden;

      // width: 100%;0 25px 0 0
    }
    @media (max-width: 450px) {
      padding: 20px 10px 20px 10px;
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .checkout {
      &__inner {
        max-height: calc(100vh - 265px);
      }
    }
    .checkout__sum-wrapper {
      position: fixed;
      height: 100px;
      bottom: 105px;
    }
  }
  // }
  &__heading--wrapper {
    @media (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }
  }
  &__items {
    overflow: scroll;
    height: 100%;
    padding-bottom: 60px;
    @media (min-width: 1201px) {
      padding-bottom: 140px;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    max-width: 500px;
    @media (max-width: 1200px) {
      max-width: 100%;
    }

    h1 {
      margin-bottom: 0;
      font-size: 32px;
      font-weight: 700;
    }
    @media (max-width: 755px) {
      h1 {
        font-size: 24px;
      }
    }
    .btn {
      background-color: transparent;
      position: relative;
      width: 155px;
      font-size: 12px;
      padding-left: 0;
      padding-right: 60px;
      z-index: 10;
      .content {
        width: 100%;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 100%;
        background-image: url('../../Img/icon-basket.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
        background-color: #ffc400;
        border-radius: 6px;
      }
    }
    @media (max-width: 1200px) {
      max-width: 500px;
      width: 100%;
    }
  }
}

.checkoutItem {
  display: flex;
  margin: 7px;
  height: 114px;
  position: relative;
  padding-right: 20px;
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    width: 110px;
    img {
      height: 100%;
      object-fit: contain;
      position: static;
      width: 100%;
      object-fit: contain;
    }
  }
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 667px) {
    padding: 0 25px 0 0;
  }
}

.checkout {
  &__bg {
    position: absolute;
    width: 22%;
    right: -17%;
    top: -10%;
    z-index: 10;
    @media (max-width: 1200px) {
      top: -6%;
      width: 23%;
    }
    @media (max-width: 756px) {
      top: -4%;
      width: 30%;
      right: -23%;
    }
  }
  &__sum--wrapper {
    padding: 0 15px 15px 15px;
    padding: 10px 15px 0px 15px;
    @media (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 1201px) {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 30;
      background-color: #fff;
    }
  }
  &__summary {
    position: relative;
    padding: 0 7px 7px 7px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &:after {
      background-color: #00000012;
      content: '';
      height: 100%;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    @media (max-width: 500px) {
      padding: 0 0 7px 7px;
    }
    .checkout__price {
      // position: relative !important;
      // padding-right: 13px;
      // @include md {
      position: absolute !important;
      right: 10px;
      // }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    padding: 0 5px;
    // justify-content: space-between;
    @media (max-width: 500px) {
      padding: 0 0 0 7px;
    }
    .counter {
      margin: 0 5px;
      @media (min-width: 400px) {
        margin-left: 10px;
      }
      &__value {
        background-color: #ededed;
        border-radius: 7px;
        min-height: 30px;
        z-index: 10;
        // border: 1px solid #fff;
        border-color: #fff;
      }
      &__inner {
        align-items: center;
      }
      &__input {
        color: #000;
        font-size: 18px;
      }
    }
    &:nth-child(2) {
      margin-top: 10px;
    }
    &:nth-child(3) {
      .checkout__price {
        font-weight: 700;
        flex-shrink: 0;
        position: absolute;
        // right: 10px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    span {
      &:first-child {
        width: 48px;
        font-weight: 600;
        @media (min-width: 400px) {
          width: 55px;
        }
      }
    }
    .bold {
      font-weight: 700;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    // margin-left: 25px;
    &--bold {
      font-size: 18px;
      font-weight: 700;
    }
  }
  &__sum {
    padding-top: 20px;
    // max-width: 400px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    p {
      font-size: 13px;
      margin-bottom: 0;
      margin-right: 7px;
    }
    a {
      text-decoration: none;
    }
    &-contact {
      font-size: 12px;
      color: #b1b1b1;
      text-decoration: underline !important;
      text-align: center;
      width: 100%;
      margin: 15px auto;
      display: block;
    }
    @media (max-width: 650px) {
      padding: 0px 15px;
    }
  }
  &__close {
    position: absolute;
    right: -30px;
    top: 0px;
    height: 20px;
    border-radius: 3px;
    z-index: 11;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      cursor: pointer;
      background-color: rgb(240, 240, 240);
      svg {
        .a {
          fill: rgb(66, 66, 66);
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    // width: 0;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    // width: 100%;
    opacity: 1;
    transform: scale(1);
  }
}
// .fade {
//   animation: fade 0.1s;
// }
