.footer {
  @media (max-width: 1366px) {
    padding: 20px 40px 80px 60px;
  }
  background-color: #090909;
  bottom: 0;
  display: grid;
  left: 0;
  width: 100%;
  z-index: 1;
  grid-template-columns: 1fr;
  @media (max-width: 756px) {
    padding: 340px 40px 87px 40px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    padding: 272px 32px 70px 32px;
  }
  @include md {
    padding: 40px 50px 57px 50px;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
  }
  @include lg {
    position: absolute;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    bottom: -40px;
  }
  @include xl {
    bottom: 0;
    padding: 40px 100px 87px 100px;
  }

  &__icon {
    img {
      @media (max-width: 1366px) {
        height: 76px;
        width: 80px;
      }
      @media (max-width: 756px) {
        width: 102px;
        height: 95px;
      }
    }
  }
  &__icon-name {
    color: #ffc400;
    font-size: 22px;
    font-weight: 700;
    margin-top: -60px;
    @media (max-width: 1366px) {
      color: #ffc400;
      font-size: 16px;
      font-weight: 700;
      margin-top: -48px;
    }
  }
  &__icon-descript {
    @media (max-width: 1366px) {
      font-size: 10pt;
    }
    color: #fff;
    opacity: 0.51;
    font-size: 16px;
    margin-top: -10px;
    margin-right: 20px;
    // max-width: 340px;
  }
}
