@mixin label-inactive() {
  transform: translateY(0);
  font-size: 0.9rem;
}

@mixin label-active() {
  transform: translateY(-14px);
  font-size: 0.6rem;
}
input::placeholder {
  // color: #969090;
  color: #cecfd0;
  font-size: 13px;
}
// input:focus::placeholder {
//   color: #3b86ff;
// }

.form__field {
  position: relative;
  // margin: 0 auto 28px auto;
  // padding-left: 30px;
  height: 37px;
  .field-label {
    @include label-active();
    position: absolute;
    left: 30px;
    font-weight: 300;
    color: #aeaeae;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    // @include rwd(1100px) {
    //   color: #f3f1f1;
    // }
  }
  .select-label {
    position: absolute;
    left: 30px;
    top: 0px;
    font-weight: 300;
    color: #aeaeae;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &--focus {
      @include label-active();
    }
  }
  .react-select {
    &__control {
      // border-style: unset;
      // border-bottom: 1px solid #e9e6e6;
      // border-radius: 0;
      // min-height: 24px;
      // height: 24px;
      // background-color: transparent;

      border-style: unset;
      min-height: 40px;
      height: 24px;
      font-size: 13px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #e8e9ec;
      color: #4d4f5c;
      padding: 0px 0 0px 12px;
      font-weight: 300;
      border-radius: 4px;
      &:hover {
        // border-color: #e9e6e6;
        border-color: #808495;
      }
      // &:focus {
      //   border-color: #3b86ff;
      //   border: 1px solid #3b86ff !important;
      // }
      &--is-focused {
        outline: none;
        box-shadow: none;
        height: 24px;
        border: 1px solid #3b86ff;
        &:hover {
          border-color: #3b86ff;
        }
        // &:hover {
        //   border-color: #e9e6e6;
        // }
      }
    }
    &--is-multi {
      &__control {
        // border-style: unset;
        // border-bottom: 1px solid #e9e6e6;
        // border-radius: 0;
        // min-height: 24px;
        // background-color: transparent;

        border-style: unset;
        min-height: 40px;
        height: 24px;
        font-size: 13px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #e8e9ec;
        color: #4d4f5c;
        padding: 0px 0 0px 12px;
        font-weight: 300;
        border-radius: 4px;
        &:hover {
          // border-color: #e9e6e6;
          border-color: #808495;
        }
        &:focus {
          border-color: #3b86ff;
          border: 1px solid #3b86ff;
        }
        &--is-focused {
          outline: none;
          box-shadow: none;
          border: 1px solid #3b86ff;
          // min-height: 24px;
          // &:hover {
          //   border-color: #e9e6e6;
          // }
        }
      }
      &__value-container {
        padding: 0;
        // min-height: 24px;
        // top: -7px;
      }
      &__menu {
        text-align: left;
      }
    }
    &__menu {
      text-align: left;
    }
    &__value-container {
      padding: 0;
      min-height: 24px;
      // top: -7px;
    }
    &__indicators {
      // top: -10px;
      position: relative;
    }
    &__placeholder {
      // color: #969090;
      color: #cecfd0;
      font-size: 13px;
      // top: 60%;
    }
    &__single-value {
      color: #4d4f5c;
      font-weight: 300;
      font-size: 13px;
      // margin-left: 0;
      overflow: visible;
    }
  }
  &--error {
    input,
    textarea,
    .react-select__control,
    .react-select--is-multi__control {
      border: 1px solid #ff6565 !important;
    }
    .react-select__input,
    .react-select--is-multi__control {
      input {
        border: none !important;
      }
    }
  }
  &--checkbox {
    height: 20px;
    // line-height: 20px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin: -10px auto 25px auto;
    font-size: 14px;
    color: #969090;
  }
  &--disabled {
    // input[type='date'] {
    //   color: #dcdbdb;
    // }
    input[type='text']::placeholder {
      color: #dcdbdb;
    }
  }
  input,
  select {
    // font-size: 1.1rem;
    // width: 100%;
    // border: none;
    // background-color: #fff;
    // border-bottom: 2px solid #efefefe3;
    // outline: none;
    // font-family: $fontSansPro;
    // color: #212020;
    // font-weight: 300;
    // @include rwd(1100px) {
    //   color: #f3f1f1;
    //   background-color: transparent;
    // }

    font-size: 13px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e8e9ec;
    outline: none;
    color: #4d4f5c;
    padding: 10px 0 10px 12px;
    font-weight: 300;
    border-radius: 4px;
    option {
      color: #444;
    }
    // &:focus {
    //   border: 1px solid #3b86ff;
    //   caret-color: #3b86ff;
    //   border-color: #3b86ff !important;
    // }
    &:hover {
      // border-color: #e9e6e6;
      // border-color: #808495;
      cursor: default;
    }

    // &:-ms-input-placeholder {
    //   ~ label {
    //     @include label-inactive();
    //   }
    // }
    // // All other browsers except Edge
    // &:placeholder-shown {
    //   ~ label {
    //     @include label-inactive();
    //   }
    // }
    // &:focus {
    //   outline: none;
    //   ~ label {
    //     @include label-active();
    //   }
    // }
  }
  input[type='checkbox'] {
    width: 20px;
    height: 17px;
  }

  .verify-success {
    margin-right: 5px;
    color: #0dbb0d;
  }
  .verify-pending {
    margin-right: 5px;
    color: #f9a101;
  }
  i {
    position: absolute;
    left: -5px;
    top: 0;
    font-size: 1.2rem;
    color: #cccbcb;
    height: 23.5px;
    display: flex;
    align-items: center;
    // @include rwd(1100px) {
    //   color: #f3f1f1;
    // }
  }
  // &--select {
  //   .used {
  //     ~ label {
  //       @include label-active();
  //     }
  //   }
  //   .un-used {
  //     ~ label {
  //       @include label-inactive();
  //     }
  //   }
  //   select {
  //     appearance: none;
  //   }
  //   &:after {
  //     position: absolute;
  //     top: 10px;
  //     right: 5px;
  //     width: 0;
  //     height: 0;
  //     padding: 0;
  //     content: '';
  //     border-left: 6px solid transparent;
  //     border-right: 6px solid transparent;
  //     border-top: 6px solid rgba(0, 0, 0, 0.17);
  //     pointer-events: none;
  //     @include rwd(1100px) {
  //       border-top: 5px solid #f3f1f1;
  //     }
  //   }
  // }
  // &--error {
  //   position: relative;
  //   top: 7px;
  //   text-align: right;
  //   font-family: $fontRaleway;
  //   font-size: 13px;
  //   font-weight: 500;
  //   color: #e20d0d;
  //   height: 13px;
  //   span {
  //     position: relative;
  //     animation: show 0.25s ease-in;
  //   }
  // }
  &--label-error {
    position: relative;
    top: 9px;
    text-align: right;
    font-size: 11px;
    font-weight: 500;
    color: #ff6565;
    height: 13px;
    span {
      position: relative;

      animation: show 0.25s ease-in;
    }
  }
  @keyframes show {
    0% {
      right: -40px;
    }
    50% {
      right: 0px;
    }
    80% {
      right: -10px;
    }
    100% {
      right: 0px;
    }
  }
}
