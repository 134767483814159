.counter {
  &__inner {
    display: flex;
    justify-content: center;
    font-size: 22px;
    line-height: 15px;
  }
  &__label {
    font-size: 10px;
    margin-bottom: 4px;
    // margin: -10px 0 10px 0;
  }
  &__value {
    position: relative;
    padding: 10px 10px 0 10px;
    min-width: 45px;
    text-align: center;
    border-bottom: 1px solid #545454;
  }
  &__input {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    background: transparent;
    color: #fff;
    border: none;
    text-align: center;
    font-size: 22px;
    outline: none;
  }

  &__btn {
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
    user-select: none;
    z-index: 2;
    @media (max-width: 1180px) {
      padding: 10px 5px;
    }
  }
}
