.order {
  display: flex;
  padding: 10px 79px 0 57px;
  overflow-y: auto;
  // height: 100%;
  min-height: 100vh;
  a {
    width: fit-content;
  }
  &__basket-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
      position: absolute;
      right: 30px;
      top: 9px;
      display: flex;
      font-size: 13px;
      font-weight: 500;

      span {
        display: flex;
        p {
          font-weight: 700;
          margin: 0 15px;
        }
      }
    }
    @media (max-width: 354px) {
      top: 20px;
    }
    &-icon {
      margin-top: -4px;
      position: relative;
      p {
        position: absolute;
        right: -5px;
        bottom: -15px;
        background-color: #ffc400;
        padding: 0 4px;
        font-size: 12px;
        font-weight: 700;
        border-radius: 50%;
      }
    }
  }
  &__left {
    width: 65%;
    // max-width: 550px;
    margin-right: 25px;
    &--heading {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 30px 0 15px;
      .order__left--bold {
        font-weight: 700;
        display: inline-block;
        font-size: 12px;
      }
      span {
        display: flex;
        font-size: 13px;
        font-weight: 500;
        @media (max-width: 768px) {
          display: none;
        }
        p {
          padding: 0 15px;
          font-weight: 700;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      &-delivery {
        margin-top: 30px;
        // @media (max-width: 768px) {
        //   margin-top: 30px;
        // }
        // @media (max-width: 1366px) {
        //   margin-top: 30px;
        // }
      }
    }
    &--basket {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
    }
    a {
      text-decoration: none;
    }

    @media (max-width: 768px) {
      position: relative;
    }
  }
  &__right {
    width: 60%;
    // max-width: 400px;
    max-width: 550px;
    @media (max-width: 1080px) {
      // max-width: 518px;
      max-width: 550px;
      .form__data--heading {
        padding-top: 35px !important;
      }
    }
    .form__data--heading {
      padding-top: 128px;
      margin-bottom: 10px;
    }
  }
  &__box {
    &-dotpay {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      align-items: center; // dodane dla visaMasterCard
      span {
        width: 62px;
        text-align: center;
        // height: 16px;
        img {
          height: 20px;
        }
      }
      a {
        text-decoration: none !important;
      }
    }
  }

  &__products-list {
    position: relative;
    padding: 16px 28px 100px 20px;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   background-color: #00000012;
    //   opacity: 31%;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    // }
  }
  &__product {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
    padding-bottom: 17px;
    &:last-of-type {
      border-bottom: unset;
      margin-bottom: 25px;
      padding-bottom: unset;
      // padding-bottom: 100px;
    }
    &--name,
    &--size,
    &--paper,
    &--prints {
      display: flex;
      font-size: 11px;
      // font-size: $fontMain;
      p {
        width: 100px;
        margin-bottom: 7px;
        font-weight: 500;
      }
      span {
        font-weight: 700;
      }
    }
    &--left {
      width: 100%;
    }
    &--right {
      // width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      min-width: 90px;
      max-width: 190px;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 15px;
      }
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 23px;
      line-height: 28px;
      padding: 0 17px;
    }
  }

  button {
    margin-bottom: 19px;
  }
  .btn--order {
    @media (max-width: 768px) {
      background-color: #f9f9f9;
      border-bottom: 1px solid #d6d6d6;
      border-radius: 0;
      justify-content: flex-start;
      padding-bottom: 25px;
      padding-left: 12px;
      width: 100%;
      font-size: 13px;
      .content {
        width: 140px;
        white-space: nowrap;
      }
    }
  }
  .btn__icon i {
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  @media (max-width: 1080px) {
    flex-direction: column;
    &__left,
    &__right {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.photo {
  height: calc(100vh - 230px);
  overflow-y: auto;
  @media (max-width: 1080px) {
    height: auto;
  }
}
.photo__single {
  display: flex;
  height: 143px;
  position: relative;
  padding-right: 40px;
  &--left {
    height: 133px;
    width: 182px;
    padding: 5px;
    flex-shrink: 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-prints {
      display: none;
      position: absolute;
      bottom: 5px;
      left: 5px;
      width: 18px;
      height: 14px;
      font-size: 10px;
      font-weight: 600;
      background-color: #ffc400;
      text-align: center;
      @media (max-width: 768px) {
        display: block;
      }
    }

    @media (max-width: 768px) {
      max-height: 78px;
      width: 100px;
    }
  }

  &--right {
    display: flex;
    width: 100%;
    padding-left: 11px;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    &--info {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      @media (max-width: 768px) {
        justify-content: space-around;
      }
    }
    &--price {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      flex-shrink: 0;
      @media (max-width: 768px) {
        justify-content: space-around;
      }
      p {
        width: 100px;
        margin-bottom: -7px;
        font-size: 10px;
        text-align: right;
        @include md {
          margin-bottom: 4px;
        }
        &:last-child {
          font-weight: 700;
          font-size: 15px;
        }
        @media (max-width: 768px) {
          width: auto;
          font-size: 13px;
        }
      }
    }
    @media (max-width: 768px) {
      border: 0;
    }
  }
  .checkout__close {
    right: 10px;
    top: 5px;
  }
  &--close {
    padding-left: 20px;
    i {
      color: #e5e5e5;
      font-size: 20px;
      cursor: pointer;
      margin-top: 5px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  &--name,
  &--size,
  &--paper,
  &--prints {
    display: flex;
    font-size: 10px;
    p {
      width: 100px;
      margin-bottom: 7px;
    }
    span {
      font-weight: 700;
    }
    @media (max-width: 768px) {
      font-size: 13px;
      p {
        width: 70px;
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #d6d6d6;
    padding-top: 4px;
    max-height: 96px;
    padding: 7px 30px 0 13px;
    &:first-child {
      border-top: 1px solid #d6d6d6;
    }
    &--name {
      display: none;
    }
  }
}

.order__box {
  width: 387px;
  height: fit-content;
  position: relative;
  margin-top: 125px;
  padding: 35px 35px 0 35px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.03);
  &--coupon {
    border-bottom: 1px solid #d6d6d6;
    height: 95px;
    position: relative;
    margin-bottom: 30px;
    input {
      border: 1px solid #d6d6d6;
      background-color: transparent;
      border-radius: 7px;
      height: 35px;
      margin-right: 5px;
      width: 100%;
      z-index: 2;
      padding: 0 10px;
      @media (max-width: 400px) {
        width: 65%;
      }
    }
  }
  &--label {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 9px;
  }
  &--form {
    .btn--success .content {
      transition-duration: 0s;
      top: 20px;
      position: absolute;
      right: 0;
      @include md {
        bottom: 54px;
      }
      svg {
        position: absolute;
        right: -120px;
        @include md {
          position: relative;
          right: 12px;
        }
      }
      p {
        position: absolute;
        right: 40px;
        @include md {
          right: 25px;
        }
      }
      @include md {
        position: absolute;
        right: 10px;
      }
    }
    display: flex;
    // align-items: flex-end;
    .form__field--text {
      width: 100%;
      border-radius: 7px;
      margin: 0;
      input {
        background: transparent;
      }
    }
    .btn {
      margin: 0 0 30px 15px;
      padding: 11px 5px;
      height: 36px;
      width: 100px;
    }
  }
  &--voucher-info {
    position: absolute;
    bottom: 7px;
    color: #b1b1b1;
    font-size: 11px;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 0;
    span {
      font-weight: 700;
      color: #222;
    }
  }
  &--voucher-error {
    @extend .order__box--voucher-info;
    color: #c45757;
  }
  &--sum {
    margin-top: 15px;

    span {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 500;
      p {
        font-weight: 600;
      }
      &:last-child {
        margin-top: 47px;
        p {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  .btn--wrapper {
    z-index: 2;
    position: relative;
    .btn {
      font-weight: 500;
    }
    .btn--finish {
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      &:after {
        content: '';
        position: absolute;
        right: 10px;
        width: 35px;
        background-image: url('../../Img/arrow-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
      }
      @media (max-width: 1080px) {
        max-width: 300px;
        margin: 0 auto 20px auto;
        position: relative;
      }
    }
    .btn--delivery {
      width: 100%;
      font-size: 12px;
      font-weight: 700;
    }
  }

  a {
    text-decoration: none;
  }
  &--help {
    color: #b1b1b1;
    font-size: 12px;
    text-decoration: underline !important;
    text-align: center;
    z-index: 2;
    display: block;
    position: relative;
  }
  @media (max-width: 1080px) {
    width: 100%;
    height: auto;
    padding-bottom: 45px;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

// delivery

.order .order__box-delivery {
  padding-bottom: 19px;
  .btn {
    &--wrapper {
      margin-top: 20px;
    }
    // background-color: #dbdbdb;
    // color: #b3b3b3;
    // pointer-events: none;
  }
  .btn--active {
    background-color: #ffc400;
    color: #000;
    pointer-events: all;
  }
  .order__box {
    &--sum {
      margin-top: 0;
      span {
        height: 26px;
      }
    }
    &-delivery {
      text-align: center;
      font-size: 12px;
      color: #ee4c4c;
    }
    &--help {
      text-decoration: none;
    }
    &-dotpay {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      span {
        width: 62px;
        // height: 16px;
        img {
          height: 20px;
        }
      }
      a {
        text-decoration: none !important;
      }
    }
    &__sumary-delivery {
      margin-top: 10px;
    }
    &-delivery-finish {
      color: #b1b1b1;
      font-size: 12px;
      text-align: center;
      margin-top: -8px;
      margin-bottom: 20px;
      display: none;
    }
  }
}

.order__box-delivery.hidden {
  display: none;
}

.order .order__box-delivery-finish.active {
  display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fade {
  animation: fade 1s;
}
