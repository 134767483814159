@mixin leftPadding {
  padding-left: 140px;
  @media (max-width: 930px) {
    padding: 50px 30px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: -250px;
    top: -100px;
    width: 850px;
    height: 100%;
    background-image: url('../../../Img/contact-background.png');
    background-repeat: no-repeat;
    @media (max-width: 1050px) {
      right: -400px;
    }
    @media (max-width: 787px) {
      right: -500px;
    }
    @media (max-width: 678px) {
      right: -680px;
    }
    @media (max-width: 501px) {
      display: none;
    }
  }
  &__section {
    width: 100%;
    padding-top: 39px;
    padding-bottom: 110px;
    &:first-of-type {
      padding-top: 79px;
    }
    &:last-of-type {
      padding-bottom: 60px;
    }
    @include leftPadding();
    &--gray {
      background: linear-gradient(0deg, #f6f6f6da, #fff 80%, #eaeaeada 100%);
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 48px;
  }
  &__subtitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.53);
    margin-bottom: 30px;
    max-width: 325px;
  }
  &__form {
    max-width: 315px;
    &--field {
      margin-bottom: 14px;

      &:nth-of-type(4) {
        height: 168px;
      }
      .form__field {
        &--number,
        &--text {
          margin: 0;
        }
      }
      .form__data--headingSmall {
        padding: 0;
      }
      .form__field--textarea {
        .form__field--label-error {
          top: 3px;
        }
      }
      textarea {
        resize: unset;
        // width: 315px;
        width: 100%;
        resize: none;
        height: 120px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        font-family: $fontMain;
        outline: none;
        padding: 10px;
      }
    }
    .btn--wrapper {
      button {
        float: right;
        padding: 11px 40px;
      }
    }
  }
  &__recaptcha {
    div {
      width: 100% !important;
    }
    margin-bottom: 30px;
  }
  &__box-wrapper {
    display: flex;
    max-width: 750px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  &__box {
    margin-right: 20px;
    width: 325px;
    @media (max-width: 749px) {
      margin-bottom: 30px;
    }
    &--subtitle {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    &--text {
      color: rgba(0, 0, 0, 0.53);
      font-size: 10px;
      font-weight: 500;
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          color: #222;
        }
      }
    }
  }
  &__phone-wrapper {
    display: flex;
    align-items: center;
  }
  &__icon {
    height: 31px;
    width: 31px;
    margin-right: 25px;
  }
  &__number {
    font-size: 33px;
    font-weight: 700;
  }
  &__pdf-box {
    margin-right: 20px;
    width: 325px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    text-decoration: none;
    &--icon {
      margin-top: 5px;
    }
    &--data {
      max-width: 250px;
      flex: 1;
      @media (max-width: 400px) {
        width: 100%;
        margin: 0 15px;
      }
      .title {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #000;
      }
      .description {
        font-size: 10px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.46);
      }
    }
  }
}
