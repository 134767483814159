@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900|Raleway:300,400,500,700,900');
@import url('https://geowidget.easypack24.net/css/easypack.css');

* {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}
.btn {
  &:active,
  &:focus {
    outline: none;
  }

  font-size: 10px;
  font-family: $fontMain;
  line-height: 1.4;
  font-weight: 700;
  color: $black;
  padding: 11px 21px;
  cursor: pointer;
  border-radius: 7px;
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  .content {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    &--hide {
      display: none;
      opacity: 0;
    }
  }
  &--new-configuration {
    // margin-right: -13px;
    width: 155px;
    white-space: nowrap;
    @media (min-width: 400px) {
      width: auto;
    }
  }
  &--back {
    white-space: nowrap;
    width: 152px;
    .content {
      width: 100%;
    }
  }
  &--loader {
    margin-right: 5px;
    &__inner {
      width: 17px;
      height: 17px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-bottom-color: transparent;
      animation: spinx 0.7s infinite linear both;
    }
  }
  &--back-order {
    @media (max-width: 768px) {
      .content {
        width: 170px !important;
      }
    }
    @media (max-width: 354px) {
      .content {
        white-space: pre-wrap !important;
        width: 100px !important;
      }
      .btn__icon {
        flex-shrink: 0;
      }
    }
  }
  // &-choose {
  //   width: 100px;
  //   .content {
  //     width: 100%;
  //     text-align: center;
  //   }
  // }
  &.is-loading {
    padding-left: 22px;
    display: flex;
    align-items: center;
  }
  &--gray {
    background-color: $gray;
  }
  &--yellow {
    background-color: $yellow;
  }
  &--icon {
    padding-top: 9px;
    padding-bottom: 9px;
    // max-height: 14px;
  }
  &--disabled {
    background-color: #dbdbdb;
    color: #a8a8a8;
    cursor: not-allowed;
    .svg {
      .a {
        stroke: #a8a8a8;
      }
    }
  }
  &--success {
    background-color: rgb(53, 221, 128);
    color: #fff;
    // opacity: var(--o, 0);

    .content {
      opacity: 1;
      animation: fade 1.3s;
    }

    svg {
      .a {
        fill: #fff !important;
        stroke: #fff !important;
      }
      height: 24px;
      width: 24px;
    }
  }
  &__icon {
    &-placement {
      &--right {
        .btn__icon {
          padding-left: 13px;
          margin-right: -10px;
          height: 17px;
          width: fit-content;
          max-width: 30px;
        }
      }
      &--left {
        .btn__icon {
          margin-right: 13px;
          // margin-left: -10px;
          height: 17px;
          width: fit-content;
          max-width: 30px;
        }
      }
    }
  }
  i {
    font-size: 16px;
  }
  &--black {
    background-color: $black;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.35);
    color: #fff;
    svg {
      .a {
        stroke: #fff;
      }
    }
  }
  &--red {
    @extend .btn--black;
    color: red;
  }
  &__no-label {
    padding: 11px;
    .btn__icon {
      padding: 0;
    }
  }
  &--clean {
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
  }
  &-basket span {
    width: 77px;
    justify-content: space-between;
    .btn__icon {
      padding-left: 2px;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spinx {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;
}

h1 {
  font-size: 43px;
  line-height: 1;
  margin-bottom: 15px;
}
.yellow {
  color: $yellow;
}
p {
  margin-bottom: 15px;
}

.add-to-cart {
  --color: #000;
  --icon: var(--color);
  --cart: #000;
  --dots: #000;
  --background: #ffc400;
  --shadow: #{rgba(#00093d, 0.16)};
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 7px;
  padding: 11px 32px;
  font-weight: 700;
  line-height: 20px;
  transform: scale(var(--s, 0.97));
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform 0.3s, box-shadow 0.3s;
  &--disabled {
    background-color: #dbdbdb;
    color: #a8a8a8;
    cursor: not-allowed;
    .svg {
      .a {
        stroke: #a8a8a8;
      }
    }
  }
  .default {
    padding-left: 16px;
    position: relative;
    opacity: var(--o, 1);
    transform: scale(var(--s, 1));
    transition: transform 0.3s, opacity 0.3s;
    transition-delay: var(--d, 0.3s);
  }
  .success {
    opacity: var(--o, 0);
    transform: translate(-50%, var(--y, 12px)) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transition: opacity 0.3s, transform 0.3s;
    transition-delay: var(--d, 0s);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 18px;
      width: 18px;
      margin-left: 15px;
    }
  }
  .dots {
    width: 3px;
    height: 3px;
    top: 17px;
    left: 50%;
    margin-left: -5px;
    border-radius: 2px;
    position: absolute;
    transform-origin: 10px 50%;
    background: var(--dots);
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
    opacity: var(--o, 0);
    transform: scale(var(--s, 0.4));
    transition: opacity 0.3s, transform 0.1s;
    transition-delay: var(--d, 0s);
  }
  .cart {
    position: absolute;
    top: 11px;
    right: -60px;
    bottom: 0;
    z-index: 1;
    border-radius: inherit;
    overflow: hidden;
    left: -125px;
    // -webkit-mask-image: -webkit-radial-gradient(white, black);
    // @media (min-width: 1000px) {
    //   left: -60px;
    // }

    svg {
      height: 17px;
      width: fit-content;
      opacity: var(--o, 1);
      transform: scale(var(--s, 1));
      transition: transform 0.3s, opacity 0.3s;
      transition-delay: var(--d, 0.3s);
    }
  }
  &.added {
    .cart {
      --o: 0;
      --s: 0.8;
      --d: 2s;
      &:after {
        --r: -90deg;
      }
    }
    .default {
      --o: 0;
      --s: 0.8;
      --d: 0s;
      &:before {
        --r: -180deg;
      }
      &:after {
        --r: -90deg;
      }
    }
    .dots {
      --o: 1;
      --s: 1;
      --d: 0.3s;
      animation: dots 2s linear forwards;
    }
    .success {
      --o: 1;
      --y: 0;
      --d: 1.8s;
    }
    .cart {
      &:before,
      & > div {
        animation: cart 2s forwards;
      }
      animation: cart 2s forwards;
    }
  }
  &:not(.added) {
    &:hover {
      --s: 1;
      --s-y: 8px;
      --s-b: 20px;
    }
    &:active {
      --s: 0.94;
      --s-y: 2px;
      --s-b: 6px;
    }
  }
}

@keyframes cart {
  41%,
  49%,
  57%,
  60% {
    transform: translateX(31px) rotate(0deg);
  }
  40%,
  47%,
  54% {
    transform: translateX(31px) rotate(0deg) translateY(1px);
  }
  100% {
    transform: translateX(180px) rotate(-16deg);
  }
}

@keyframes dots {
  5% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-22px) rotate(-32deg);
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  }
  31% {
    box-shadow: 5px -4px 0 var(--dots), 10px -8px 0 var(--dots);
  }
  32%,
  50% {
    transform: translateY(-3px) rotate(0deg);
    opacity: 1;
  }
  45%,
  100% {
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
  }
  51%,
  100% {
    opacity: 0;
  }
}
